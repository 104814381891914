import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import c from "classnames";
import Count from "./Count";
import { connect } from "react-redux";
import { ScrollContext } from "../../context/scroll";
import wrapUsername from '../../utils/wrap-username'

const MyDisplay = ({ history, location: { pathname }, user: { success, data: { username, _id, saved_by_users, following } }, myPosts: { list }, savedPosts, isShownAll }) => {
    const { setTranslate } = useContext(ScrollContext);
    const followingCount = following.length

    const openProfile = async () => {
        await history.push("/me");
        setTranslate(-1000);
    };

    return (
        <div className={c("user-display", { deactived: !isShownAll })}>
            <div className="user-display__info">
                <h4 onClick={openProfile} className="user-display__info__link">{wrapUsername(username)}</h4>
                <div className="user-display__info__meta">
                    <Count count={list.length} countName="Posts" />
                    <Count count={savedPosts.list.length} countName="Saves" />
                    <Count count={followingCount} countName="Following" />
                </div>
            </div>
        </div>
    );
};

export default withRouter(connect(state => {
    return { user: state.user, myPosts: state.myPosts, savedPosts: state.savedPosts }
})(MyDisplay));
