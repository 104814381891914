import React, {useContext, useState} from "react";
import {Link, useHistory} from 'react-router-dom'
import {requestRegister} from "../../../ducks/auth/actions";
import {connect} from "react-redux";
import {SystemContext} from "../../../context/system";
import {ScrollContext} from "../../../context/scroll";
import {ModalContext} from "../../../context/modal";

const SignUpModal = ({closeModal, requestRegister}) => {
    const { setModalType } = useContext(ModalContext);
    const { startLoop, stopLoop } = useContext(ScrollContext)

    const create = () => {
        window.open('/register', '_self')
    }
    const login = () => {
        window.open('/login', '_self')
    }

    const close = () => {
        startLoop()
        closeModal()
        setTimeout(() => {
            setModalType('signup')
            stopLoop()
        }, 10000)
    }

    return (
        <div id="currentModal"
             className="add-post-modal"
             onClick={e => e.target.id === "currentModal" && close()}
        >
            <div style={{ maxHeight: '125px' }} className="post-modal-container">
                <img style={{ filter: 'drop-shadow(0 0 3px #FFF)', position: 'absolute', top: '-140px', width: '100px' }} src="/images/logo-new.png" alt=""/>
                <span className="corner top-right"><i className="fas fa-chevron-up"/></span>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="post-modal-content">
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                        <button style={{
                            background: 'transparent',
                            border: '1px solid #fff',
                            color: '#fff',
                            fontSize: '18px',
                            padding: '5px 15px',
                            position: 'relative',
                            outline: 'none',
                            width: '110px'
                        }} onClick={create}>
                           Register
                        </button>
                        <button style={{
                            background: 'transparent',
                            border: '1px solid #fff',
                            color: '#fff',
                            fontSize: '18px',
                            padding: '5px 15px',
                            position: 'relative',
                            outline: 'none',
                            width: '110px'
                        }} onClick={login}>
                            Login
                        </button>
                    </div>
                </div>
                <span className="corner bottom-left"><i className="fas fa-chevron-up"/></span>
            </div>
        </div>
    );
};
export default connect(null, {requestRegister})(SignUpModal)
