import { ERROR_FIND_USERS, REQUEST_FIND_USERS, SUCCESS_FIND_USERS } from "./actions";

const initialState = {
  loading: false,
  loaded: true,
  error: null,
  success: false,
  list: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_FIND_USERS:
      return { ...state, loading: true, loaded: false, list: [], error: null, success: false };
    case SUCCESS_FIND_USERS:
      return { ...state, loading: false, loaded: true, error: null, success: true, list: payload.reverse() };
    case ERROR_FIND_USERS:
      return { ...state, loading: false, loaded: false, error: payload, success: false };
    default:
      return state;
  }
}
