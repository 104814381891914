import React, { useEffect } from "react";
import { connect } from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import UserDashboard from "./Containers/UserDashboard";
import HomePage from "./pages/HomePage";
import UserPage from "./pages/UserPage";
import Profile from "./pages/Profile";
import Modals from "./Components/Modals";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import SavedPostsPage from "./pages/SavedPostsPage"
import Preloader from "./Components/Preloader"
import { bootRequest } from "./ducks/auth/actions";
import { AuthRoute, UnAuthRoute } from "./shared";

const Main = ({ user, bootRequest }) => {

  useEffect(() => {
    bootRequest()
  }, [user.data._id]);

  return (
    <Router>
      <Switch>
        <UnAuthRoute user={user} path="/login" component={LoginPage} />
        <UnAuthRoute user={user} path="/register" component={RegisterPage} />
        <UserDashboard>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/home" component={HomePage}/>
          <AuthRoute exact path="/users/:id" component={UserPage}/>
          <AuthRoute exact path="/me" component={Profile}/>
          <AuthRoute exact path="/me/favourites" component={SavedPostsPage} />
        </UserDashboard>
      </Switch>
      <Modals/>
      <Preloader />

    </Router>
  );
}

export default connect(state => ({ user: state.user }), { bootRequest })(Main)
