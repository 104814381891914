import React, {useState} from "react";
import c from "classnames";
import {requestPosts, deletePost} from "../../../ducks/posts/actions";
import {connect} from "react-redux";
import {requestUser} from "../../../ducks/user/actions";
import {withRouter} from "react-router-dom"
import parseYoutubeLink from "../../../utils/parse-youtube-link";
import YouTube from "react-youtube";

const ExpandModal = ({ postData, user, visible, closeModal, requestPosts, deletePost }) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [video, setVideo] = useState(postData.content);
  const currentUser = user.data._id

  const confirmStyle = {
    opacity: visibleConfirm ? 1 : 0,
    zIndex: visibleConfirm ? 10000 : -10000
  };

  const onDeletePost = () => {
    deletePost(postData._id, closeModal);
    setVisibleConfirm(false);
  };

  return (
    <>
      <div className="expand-modal__confirm" style={confirmStyle}>
        <div>
          <p>Are you sure you want to delete this post?</p>
        </div>
        <div>
          <button
            type="button" className="btn btn-primary"
            onClick={() => setVisibleConfirm(false)}
          >
            no
          </button>
          <button type="button" className="btn btn-danger" onClick={onDeletePost}>yes</button>
        </div>
      </div>
      <div key={postData._id} id="expandedModal" className={c("expand-modal", {chosen: visible})}
           onClick={e => e.target.id === "expandedModal" && closeModal()}>
        <div className="expand-modal__content">
          {currentUser === postData.user_id && (
            <div className="expand-modal__content__icons">
              <i className="fas fa-trash-alt" onClick={() => setVisibleConfirm(true)}/>
            </div>
          )}
          <i className="fas fa-times expand-modal__content__mark" onClick={closeModal}/>
          <YouTube videoId={parseYoutubeLink(video)} />
        </div>
      </div>
    </>
  );
};

export default withRouter(connect(state => ({user: state.user}), {requestPosts, requestUser, deletePost})(ExpandModal));
