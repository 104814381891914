import { REQUEST_SET_THEME, ERROR_SET_THEME, SUCCESS_SET_THEME } from "./actions";

const initialState = {
    themes: {
        solidThemes: [
            "theme-solid__bright-cyan",
            "theme-solid__desaturated-cyan",
            "theme-solid__strong-cyan",
            "theme-solid__soft-cyan",
            "theme-solid__dark-cyan",
            "theme-solid__soft-pink",
            "theme-solid__soft-red",
            "theme-solid__dark-blue"
        ],
        gradientThemes: [
            "theme-gradient__light-blue",
            "theme-gradient__pink",
            "theme-gradient__red",
            "theme-gradient__blue"
        ],
        imageThemes: [
            "theme-picture-1",
            "theme-picture-2",
            "theme-picture-3",
            "theme-picture-4",
        ],
        threeDTheme: [
            "theme-sky",
            "theme-forest"
        ]
    }
};

export default (state = initialState, action) => {
    const { type } = action;
    if (type === SUCCESS_SET_THEME) {
        return { ...state };
    } else { return state; }
};
