import React, { useState } from "react";
import { connect } from "react-redux";
import { requestPosts, createPost } from "../../../ducks/posts/actions";
import Categories from "./Categories";
import People from "./People";

const Content = ({ requestPosts, createPost, closeModal }) => {
    const [showOnlyMine, toggleShowOnlyMine] = useState(true)
    //const { followingUsers } = useSelector(state => state.user)

    return (
        <div className="new-layout-container">
            <span className="corner top-left" />
            <div className="new-layout-content">
                <div style={{ marginBottom: '10px' }} className="small-nav">
                    <span onClick={() => toggleShowOnlyMine(true)} style={{ color: '#FFF', cursor: 'pointer', textDecoration: showOnlyMine ? 'underline' : 'none', fontWeight: 'bold', fontSize: '22px', marginRight: '25px' }}>You Follow</span>
                    <span onClick={() => toggleShowOnlyMine(false)} style={{ color: '#FFF', cursor: 'pointer', textDecoration: showOnlyMine ? 'none' : 'underline', fontWeight: 'bold', fontSize: '22px' }}>Search</span>
                </div>
                {
                    showOnlyMine &&
                    <div style={{ alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'space-around' }} className="block-list">
                        <div style={{ marginBottom: '15px' }} className="section-block">
                            <div className="head"><span>Categories</span></div>
                            <div style={{ marginLeft: 0 }} className="main">
                                <Categories onlyMine={true} />
                            </div>
                        </div>
                        <div className="section-block">
                            <div className="head"><span>People</span></div>
                            <div style={{ marginLeft: 0 }} className="main">
                                <People onlyMine={true} />
                            </div>
                        </div>
                    </div>
                }
                {
                    !showOnlyMine &&
                    <div style={{ alignItems: 'flex-start', flexWrap: 'wrap', justifyContent: 'space-around' }} className="block-list">
                        <div style={{ marginBottom: '15px' }} className="section-block">
                            <div className="head"><span>Categories</span></div>
                            <div style={{ marginLeft: 0 }} className="main">
                                <Categories />
                            </div>
                        </div>
                        <div className="section-block">
                            <div className="head"><span>People</span></div>
                            <div style={{ marginLeft: 0 }} className="main">
                                <People />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <span className="corner bottom-right" />
        </div>
    );
};

export default connect(null, { requestPosts, createPost })(Content);
