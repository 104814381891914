import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import Bubble from "../Bubble/index";
import TextBubble from "../Bubble/TextBubble";
import NewsBubble from "../Bubble/NewsBubble";
import ImageBubble from "../Bubble/ImageBubble";
import VideoBubble from "../Bubble/VideoBubble";
import {ScrollContext} from "../../../context/scroll";
import {connect} from "react-redux";
import {setShownItem, showNextItem, showPrevItem} from "../../../ducks/visited/actions";
import {withRouter} from "react-router-dom";
import {closeSavedPosts} from "../../../ducks/savedPosts/actions";
import {SystemContext} from "../../../context/system";

function parseComplexStyleProperty(str) {
  const regex = /(\w+)\((.+?)\)/g,
    transform = {};
  let match;

  while ((match = regex.exec(str))) transform[match[1]] = match[2];

  return transform;
}

const BubblesList = (
  {
    user,
    posts,
    visited: {visited, shownItem, firstShow},
    savedPosts: {isShownSavedPost, savedPosts = []},
    showNextItem,
    showPrevItem,
    setShownItem,
    history,
    closeSavedPosts
  }) => {
  const animation = useRef(null);
  const sceneRef = useRef(null);
  const {
    isRunning, stopLoop,
    translate, setTranslate, speed
  } = useContext(ScrollContext);
  const [touchStart, setTouchStart] = useState({x: 0, y: 0});
  const [touchMove, setTouchMove] = useState({x: 0, y: 0});
  const upTimeout = useRef(null);
  const downTimeout = useRef(null);
  const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame;
  const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame;

  const {
    toggleSettingsSider
  } = useContext(SystemContext);

  useEffect(() => {
    return () => {
      setTranslate(-1000);
      cancelAnimationFrame(animation.current);
      clearTimeout(upTimeout.current);
      upTimeout.current = undefined;
      clearTimeout(downTimeout.current);
      downTimeout.current = undefined;
      closeSavedPosts();
    };
  }, []);

  useLayoutEffect(() => {
    if (isRunning) {
      animation.current = requestAnimationFrame(() => {
        setTranslate(translate + (speed * 2) / 10);
      });
    }
    return () => cancelAnimationFrame(animation.current);
  }, [isRunning, translate]);

  const swipeLeft = async () => {
    const last = visited.length - 1;
    await firstShow ? setShownItem(visited.length - 1) : showPrevItem();
    isShownSavedPost && closeSavedPosts();
    const profileId = shownItem === 0 ? last : shownItem - 1;
    await history.push(`/users/${visited[profileId].user_id}`);
    setTranslate(-1000);
  };
  const swipeRight = async () => {
    const last = visited.length - 1;
    await showNextItem();
    const profileId = shownItem === last ? 0 : shownItem + 1;
    isShownSavedPost && closeSavedPosts();
    await history.push(`/users/${visited[profileId].user_id}`);
    setTranslate(-1000);
  };

  const onWheel = e => {
    stopLoop();
    const scene = e.currentTarget.childNodes[0];
    const {translateZ} = parseComplexStyleProperty(scene.style.transform);
    if (e.deltaY < 0 && parseInt(translateZ) < -1000) return;
    const value =
      e.deltaY > 0
        ? parseInt(translateZ) + 50 * 2
        : parseInt(translateZ) - 50 * 2;
    setTranslate(value);
  };

  const onTouch = e => {
    const touchEnd = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY
    };
    const swipedX = Math.abs(touchEnd.x - touchStart.x) > 100;
    const swipedY = Math.abs(touchEnd.y - touchStart.y) > 100;
    const scene = sceneRef.current;
    const {translateZ} = parseComplexStyleProperty(scene.style.transform);
    if (swipedY) stopLoop();
    if (e.type === "touchend") {
      if (swipedX && !swipedY && touchEnd.x < touchStart.x) {
        if (!visited.length) return true;
        swipeLeft();
      }
      if (swipedX && !swipedY && touchEnd.x > touchStart.x) {
        if (!visited.length) return true;
        swipeRight();
      }
    } else {
      setTouchMove({
        x: e.changedTouches[0].clientX,
        y: e.changedTouches[0].clientY
      });
      if (!swipedX && swipedY && touchEnd.y < touchMove.y) {
        if (parseInt(translateZ) < -1000) return;
        upTimeout.current = setTimeout(() => setTranslate(parseInt(translateZ) - 50 * 2), 0);
      }
      if (!swipedX && swipedY && touchEnd.y > touchMove.y) {
        downTimeout.current = setTimeout(() => setTranslate(parseInt(translateZ) + 50 * 2), 0);
      }
    }
  };

  return (
    <div
      onClick={() => toggleSettingsSider(false)}
      className="new-bubbles-viewport"
      onTouchStart={e => setTouchStart({x: e.targetTouches[0].clientX, y: e.targetTouches[0].clientY})}
      onTouchMove={e => onTouch(e)}
      onTouchEnd={e => onTouch(e)}
      onWheel={onWheel}
    >
      <div
        className="new-bubbles-viewport__scene3D" ref={sceneRef}
        style={{
          transform: `translateZ(${translate}px)`,
          textTransform: 'uppercase', lineHeight: '1.8em', wordSpacing: '3px', letterSpacing: '2px', fontSize: '23px',
        }}
      >
        {posts.length
            ? [...posts].map((ltI, ind) => {
              if ((ind + 1) * 600 > (translate - 3000) && (ind + 1) * 600 < (translate + 3000)) {
                if (ind === posts.length - 1 && translate >= (posts.length - 1) * 600 ) {
                  setTranslate(-1000)
                }
                if (ltI.type === 'image') return <ImageBubble post={ltI} key={ltI._id} index={ind} />
                if (ltI.type === 'text') return <TextBubble post={ltI} key={ltI._id} index={ind} />
                if (ltI.type === 'news') return <NewsBubble post={ltI} key={ltI._id} index={ind} />
                if (ltI.type === 'video') return <VideoBubble post={ltI} key={ltI._id} index={ind} />
              }
            })
            : null
        }
      </div>
    </div>
  );
};

export default withRouter(connect(state => ({ user: state.user, visited: state.visited, savedPosts: state.savedPosts}), {
  showNextItem,
  showPrevItem,
  setShownItem,
  closeSavedPosts
})(BubblesList));