import React, {useContext} from "react";
import ControlBar from "./LeftControls";
import AdditionalControls from "./RightControls";
import SearchControl from "./SearchControl";
import VisitedList from "./VisitedList";
import {SystemContext} from "../../../context/system";

const ControlContainer = ({ toggleAll, isShownAll }) => {

    const { toggleSettingsSider } = useContext(SystemContext);

    return (
        <div className="controls">
            <ControlBar toggleAll={toggleAll} isShownAll={isShownAll}
            />
            {
                isShownAll && (
                    <>
                        { localStorage.getItem('token') && <VisitedList /> }
                        <SearchControl />
                        <AdditionalControls />
                    </>
                )
            }
        </div>
    );
};

export default ControlContainer;
