import React from "react";

const UserLogo = ({ img }) => {
    return (
        <div className="user-logo__wrapper">
            <div className="user-logo__outer">
                <div className="user-logo__inner" style={{ backgroundImage: `url(${img})` }} />
            </div>
        </div>
    );
};

export default UserLogo;
