import React, { useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import {ScrollContext} from "../../context/scroll";
import c from "classnames";

const MyLogo = ({ user: { success, data: { _id, img } }, location: {pathname}, history }) => {
    const {setTranslate} = useContext(ScrollContext);

    const goToMyProfile = () => {
        history.push(`/me`);
        setTranslate(-1000);
    };
    return (
        <div onClick={goToMyProfile} className='user-logo__wrapper'>
            <div className={c('user-logo__outer', {'active-item': pathname === '/me'})}>
                <div className='user-logo__inner user-logo__hover' style={{ backgroundImage: `url(${img})` }} />
            </div>
        </div>
    );
};

export default withRouter(connect(state => ({ user: state.user }))(MyLogo));
