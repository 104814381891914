import React, { useState } from "react";
import Text from "../../PostForms/Text";
import Image from "../../PostForms/Image";
import Video from "../../PostForms/Video";
import c from 'classnames'
import { connect } from "react-redux";
import { requestPosts, createPost } from "../../../ducks/posts/actions";

const NewPost = ({ requestPosts, createPost, closeModal }) => {
    const [type, setType] = useState("image");
    const [isUploading, setIsUploading] = useState(false);

    const submit = data => {
        createPost(data, closeModal);
    };

    const formContentProps = { submit, isUploading, setIsUploading };

    const formContent = {
        text: () => <Text { ...formContentProps } />,
        image: () => <Image { ...formContentProps } />,
        video: () => <Video { ...formContentProps } />
    };

    return (
        <div className="post-modal-container">
            <span className="corner top-right"><i className="fas fa-chevron-up" /></span>
            <div className="post-modal-content">
                <div className="type-selection">
                    <span onClick={() => setType('image')} className={c('type-selection__button', { selected: type === 'image' })}>PHOTO</span>
                    <span onClick={() => setType('text')} className={c('type-selection__button', { selected: type === 'text' })}>TEXT</span>
                    <span onClick={() => setType('video')} className={c('type-selection__button', { selected: type === 'video' })}>VIDEO</span>
                </div>
                <div className="post-modal-content__data-fields">
                    {formContent[type]()}
                </div>
            </div>
            <span className="corner bottom-left"><i className="fas fa-chevron-up" /></span>
        </div>
    );
};

export default connect(null, { requestPosts, createPost })(NewPost);
