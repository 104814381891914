import http from "../../utils/axios-interceptor";
export const REQUEST_FIND_USERS = 'REQUEST_FIND_USERS'
export const SUCCESS_FIND_USERS = 'SUCCESS_FIND_USERS'
export const ERROR_FIND_USERS = 'ERROR_FIND_USERS'

export const requestFindUsers = (value) => async dispatch => {
  dispatch({ type: REQUEST_FIND_USERS })
  try {
    const res = await http.get(`/api/user/find?search=${value}`)
    dispatch({ type: SUCCESS_FIND_USERS, payload: res.data.users })
  } catch (err) {
    dispatch({ type: ERROR_FIND_USERS }, err)
  }
}
