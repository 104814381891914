import http from "../../utils/axios-interceptor";

export const REQUEST_USER_POSTS = "REQUEST_USER_POSTS";
export const SUCCESS_USER_POSTS = "SUCCESS_USER_POSTS";
export const ERROR_USER_POSTS = "ERROR_USER_POSTS";

export const requestUserPosts = (userId) => async dispatch => {
  dispatch({ type: REQUEST_USER_POSTS })
  try {
    const res = await http.get('/api/posts/user-posts/' + userId)
    dispatch({ type: SUCCESS_USER_POSTS, payload: res.data.posts })
  } catch (err) {
    dispatch({ type: ERROR_USER_POSTS }, err)
  }
}
