import React from 'react'

import {chunkArray} from "../../../utils/chunk-array";
import Carousel, { arrowsPlugin } from '@brainhubeu/react-carousel';
import {useSelector, useDispatch} from "react-redux";
import { updateUserCategories } from "../../../ducks/user/actions";


export default ({ onlyMine }) => {
    const dispatch = useDispatch()
    const categories = useSelector(state => state.categories.list);

    const { data } = useSelector(state => state.user)
    const myCategories = data.categories || []

    const list = onlyMine ? myCategories : categories.map(i => i.name)
    const chunks = chunkArray(list, 5)
    const toggleCategory = (category) => {
        const isSelected = myCategories.some(c => c === category)
        const list = isSelected
            ? [...myCategories].filter(c => c !== category)
            : [...myCategories].concat([category])
        dispatch(updateUserCategories(list))
    }
    const getIcon = (categoryName) => {
        return categories.find(c => c.name === categoryName)?.content || '';
    }

    return (
        <div style={{ width: "540px", margin: "auto" }} className="left-total">
            <div className="total-title">
                [<span>{list.length}</span>]
            </div>
            <Carousel
                plugins={[
                    {
                        resolve: arrowsPlugin,
                        options: {
                            arrowLeft: <i className="fas fa-angle-left" style={{ fontSize: '30px', color: '#FFF', cursor: 'pointer' }}/>,
                            arrowLeftDisabled:<i className="fas fa-angle-left" style={{ fontSize: '30px', color: '#383838', cursor: 'pointer' }}/>,
                            arrowRight: <i className="fas fa-angle-right" style={{ fontSize: '30px', color: '#FFF', cursor: 'pointer' }} />,
                            arrowRightDisabled: <i className="fas fa-angle-right" style={{ fontSize: '30px', color: '#383838', cursor: 'pointer' }} />,
                            addArrowClickHandler: true,
                        }
                    }
                ]}
            >
                {
                    chunks.map((chunk, i) => {
                        return (
                            <div key={i} style={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
                                {
                                    chunk.map((item) => {
                                        const selected = !onlyMine && myCategories.includes(item)
                                        return (
                                            <div key={item} onClick={() => toggleCategory(item)} className="circle-item" style={{ flexBasis: '20%', opacity: selected ? 0.35 : 1, cursor: 'pointer' }}>
                                                <div className="image">
                                                    <img src={getIcon(item)} alt="Category" className="category-icon" />
                                                </div>
                                                <span className="text">{ item }</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}