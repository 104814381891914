import React, {useContext, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {ScrollContext} from "../../../context/scroll";
import {ModalContext} from "../../../context/modal";
import c from "classnames";
import {connect, useDispatch} from "react-redux";
import { visitUser } from "../../../ducks/visitedUsers/actions";
import {toggleSavePost} from "../../../ducks/savedPosts/actions";
import {requestUser} from "../../../ducks/user/actions";
import CommentsPopup from "../../Comments/CommentsPopup";
import EditCaptionModal from "../../Modals/EditCaptionModal/EditCaptionModal";
import wrapUsername from '../../../utils/wrap-username'

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
const NewsBubble = (
    {
        post,
        index, savedPosts: {list},
        user, visitUser,
        toggleSavePost,
    }) => {
    const bubble = useRef(null);
    const {translate} = useContext(ScrollContext);
    const {expandPost} = useContext(ModalContext);
    const [isSetUp, setUp] = useState(false);
    const [tr, updateTranslate] = useState({});
    const [visible, toggle] = useState(false);
    const [far, isFar] = useState(true);
    const [commentsVisibility, toggleCommentsVisibility] = useState(false);
    const [editCaptionVisibility, toggleEditCaptionVisibility] = useState(false);
    const [title, setTitle] = useState(post.title)
    const [cont, setCont] = useState(post.content)

    const isPostSaved = list.some(i => i._id === post._id);

    useEffect(() => {
        const side = index % 2 === 0 ? -1 : 1;
        updateTranslate({
            x: getRandomInt(150, 300) * side + "%",
            y: getRandomInt(-50, 200) * 1 + "%",
            z: 200 * (index + 1) * -3 + "px"
        });
        setUp(true);
    }, []);

    useEffect(() => {
        let transform = bubble.current.style.transform;
        if (transform) {
            transform = transform.split(" ")[2].replace(")", "");
            toggle(Math.abs(parseFloat(transform)) - translate < 1500);
            isFar(Math.abs(parseFloat(transform)) - translate > 3500);
        }
    }, [isSetUp, translate]);

    const titleHandler = (editedTitle) => {
        if (post.type === "text") setCont(editedTitle)
        else setTitle(editedTitle)
    }

    const handleLink = () => {
        if (!localStorage.getItem('token')) {
            return '/'
        }
        return user.data._id === post.user_id
            ? "/me"
            : `/users/${post.user_id}`;
    }

    const userClick = () => {
        if (!localStorage.getItem('token')) {
            return
        }
        user.data._id !== post.poster[0]._id && visitUser(post.poster[0]);
    };

    const bubbleBackground = {backgroundImage: `url(${cont})`}

    return (
        <div
            style={{transform: `translate3D(${tr.x || "0px"}, ${tr.y || "0px"}, ${tr.z || "0px"})`}}
            className={c("bubble", {close: !far})}
            ref={bubble}
        >
            {
                isSetUp && (
                    <>
                        <div className="bubble__around">
                            <Link to={handleLink} onClick={userClick}>
                                {!post.poster[0].img
                                    ? <i className="fa fa-user-circle bubble__around__icon"/>
                                    : <div className="bubble__around__small"
                                           style={{backgroundImage: `url(${post.poster[0].img})`}}/>
                                }
                            </Link>
                            <div className={c("bubble__around__actions", {visible})}>
                                {user.data._id === post.user_id ?
                                    <i className={"fas fa-edit"} onClick={() => toggleEditCaptionVisibility(!editCaptionVisibility)}/>
                                    : null
                                }
                                <i className={`${isPostSaved ? "fa" : "far"} fa-bookmark`}
                                   onClick={() => {
                                       if (!localStorage.getItem('token')) {
                                           return
                                       }
                                       toggleSavePost(isPostSaved, post)
                                   }}/>
                                <i className={"far fa-comment"}
                                   onClick={() => {
                                       if (!localStorage.getItem('token')) {
                                           return
                                       }
                                       toggleCommentsVisibility(!commentsVisibility)
                                   }}
                                />
                            </div>
                            {index % 2 === 0 ?
                                <CommentsPopup visible={commentsVisibility} postId={post._id} posts={post} direction={"right"}/>:
                                <CommentsPopup visible={commentsVisibility} postId={post._id} posts={post} direction={"left"}/>
                            }
                            <EditCaptionModal titleHandler={titleHandler} visible={editCaptionVisibility} post={post}/>
                        </div>

                        {
                            index % 2 === 0
                                ? (
                                    <div style={{ visibility: 'hidden', color: '#FFF', position: 'absolute', bottom: '-185px', paddingTop: '115px', left: 'calc(50% - 1px)', }} className={c("", {visible})}>
                                        <div style={{ paddingTop: '120px', borderLeft: '2px solid rgba(255,255,255,0.3)' }}>
                                        <span style={{ display: 'inline-block', padding: '0 0 20px 20px' }}>
                                            { wrapUsername(post.poster[0].username) }
                                        </span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ padding: '0 0 20px 20px', width: '371px', borderTop: '1px solid rgba(255,255,255,0.3)' }} className="" />
                                            <div style={{ width: '40px', height: '40px', borderRadius: '50%', border: '1px solid rgba(255,255,255,0.3)', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: '-22px', bottom: '0px' }} className="circle">
                                                <div style={{ width: '20px', height: '20px', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '50%' }} />
                                            </div>
                                            <div style={{ position: 'absolute', bottom: '0', left: '0', width: '75px', backgroundColor: 'rgba(255, 255, 255, 0.3)', height: '20px' }} />
                                            <div style={{ padding: '45px', maxWidth: '800px', minWidth: '800px', position: 'absolute', right: '-800px', display: 'inline-block', wordBreak: 'break-word', background: 'rgba(255,255,255,0.3)' }}>
                                                <span className="text" style={{ lineHeight: '1.8em' }} onClick={() => window.open(post.url, '_blank')} dangerouslySetInnerHTML={{__html: title}}/>
                                                <div style={{ borderRight: '3px solid #FFF', borderBottom: '3px solid #FFF', width: '20px', height: '20px', position: 'absolute', right: '-10px', bottom: '-10px' }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                : (
                                    <div style={{ visibility: 'hidden', color: '#FFF', position: 'absolute', bottom: '-185px', paddingTop: '115px', right: 'calc(50% - 1px)', }} className={c("", {visible})}>
                                        <div style={{ paddingTop: '120px', borderRight: '2px solid rgba(255,255,255,0.3)', textAlign: 'right' }}>
                                        <span style={{ display: 'inline-block', padding: '0 20px 20px 0' }}>
                                            { wrapUsername(post.poster[0].username) }
                                        </span>
                                        </div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ padding: '0 0 20px 20px', width: '371px', borderTop: '1px solid rgba(255,255,255,0.3)' }} className="">

                                            </div>
                                            <div style={{ width: '40px', height: '40px', borderRadius: '50%', border: '1px solid rgba(255,255,255,0.3)', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left: '-22px', bottom: '0px' }} className="circle">
                                                <div style={{ width: '20px', height: '20px', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: '50%' }} />
                                            </div>
                                            <div style={{ position: 'absolute', bottom: '0', right: '0', width: '75px', backgroundColor: 'rgba(255, 255, 255, 0.3)', height: '20px' }} />
                                            <div style={{ padding: '45px', maxWidth: '800px', minWidth: '800px', position: 'absolute', left: '-800px', display: 'inline-block', wordBreak: 'break-word', background: 'rgba(255,255,255,0.3)' }}>
                                                <span className="text" style={{ lineHeight: '1.8em' }} onClick={() => window.open(post.url, '_blank')} dangerouslySetInnerHTML={{__html: title}}/>
                                                <div style={{ borderLeft: '3px solid #FFF', borderBottom: '3px solid #FFF', width: '20px', height: '20px', position: 'absolute', left: '-10px', bottom: '-10px' }} />
                                            </div>
                                        </div>
                                    </div>
                                )
                        }

                        <div
                            className="bubble__background"
                            style={bubbleBackground}
                            onClick={() => expandPost(post)}
                        >
                            {post.type === 'text' && cont}
                        </div>
                    </>
                )
            }
        </div>
    );
};

export default connect(state => ({savedPosts: state.savedPosts, user: state.user}), {
    visitUser,
    requestUser,
    toggleSavePost,
})(NewsBubble);

