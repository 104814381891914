import http from '../../utils/axios-interceptor'
export const REQUEST_ANOTHER_USER = 'REQUEST_ANOTHER_USER'
export const SUCCESS_ANOTHER_USER = 'SUCCESS_ANOTHER_USER'
export const ERROR_ANOTHER_USER = 'ERROR_ANOTHER_USER'

export const requestAnotherUser = (id) => async dispatch => {
  dispatch({type: REQUEST_ANOTHER_USER})
  try {
    const res = await http.get(`/api/user/another/${id}`)
    dispatch({ type: SUCCESS_ANOTHER_USER, payload: res.data.user })
  } catch (err) {
    dispatch({ type: ERROR_ANOTHER_USER, payload: err })
  }
}
