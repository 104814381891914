import React, {useEffect, useState, useCallback, useContext} from "react";
import {connect} from "react-redux";
import {requestUser} from "../ducks/user/actions";
import {requestPosts, requestGuestPosts} from "../ducks/posts/actions";
import ControlContainer from "../Components/Dashboard/Controls/ControlContainer";
import UserDisplay from "../Components/User/UserDisplay";
import {withRouter, Redirect, Link, useHistory} from "react-router-dom";
import {requestSavedPosts} from "../ducks/savedPosts/actions";
import Background from "../Components/Background";
import MyDisplay from "../Components/User/MyDisplay";
import SiderOptions from '../Components/SiderOptions'
import {SystemContext} from "../context/system";
import {ModalContext} from "../context/modal";
import {ScrollContext} from "../context/scroll";

const UserDashboard = ({user, location: {pathname}, requestUser, requestPosts, requestGuestPosts, requestSavedPosts, children, history, match, visitedUsers}) => {
    const { toggleSettingsSider } = useContext(SystemContext);
    const { setModalType } = useContext(ModalContext);
    const { isRunning, stopLoop, startLoop, setSpeed, translate, setTranslate } = useContext(ScrollContext)
    const [pre, setPre] = useState(true);
    const [isShownAll, setIsShownAll] = useState(true);
    const [mouseDown, setMD] = useState(false);
    const [mouseUp, setMU] = useState(true);
    const [xMouseDown, setXMD] = useState(0);
    const [called, setCalled] = useState(false)
    const [tick, setTick] = useState(1)

    const isAdmin = user.data.role === 'master'

    useEffect(() => {
        if (user.data._id) {
            requestPosts(0);
            requestSavedPosts();
            setSpeed(user.data.scrollSpeed)
        }
    }, [user.data._id]);

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            requestGuestPosts(0);
            setTimeout(() => {
                setModalType('signup')
                stopLoop()
            }, 10000)
        }
        setTimeout(() => {
            setPre(false)
            document.getElementById('react-dashboard').focus()
        }, 1000)
    }, [])

    useEffect(() => {
        if (translate > tick * 15000) {
            setTick(tick + 1)
            if (localStorage.getItem('token')) {
                requestPosts(tick)
            } else {
                requestGuestPosts(tick)
            }
        }
    }, [translate])


    const onKeyDown = useCallback((e) => {
        if (e.key === 'ArrowLeft') screenGoLeft()
        if (e.key === 'ArrowRight') screenGoRight()
        if (e.key === 'ArrowUp') {
            setTranslate(translate + 50)
        }
        if (e.key === 'ArrowDown') setTranslate(translate - 50)

        if (e.keyCode === 32) {
            isRunning ? stopLoop() : startLoop()
        }
    }, [isRunning, stopLoop, startLoop])

    const {
        setBounce,
        clearTO
    } = useContext(SystemContext);

    const toggleAll = () => {
        setIsShownAll(!isShownAll);
        toggleSettingsSider(false)
    };

    const create = () => {
        window.open('/register', '_self')
    }
    const login = () => {
        window.open('/self', '_self')
    }

    const handleMouseDown = useCallback((e) => {
        setMD(true);
        setMU(false);
        setXMD(e.screenX)
    }, [mouseDown, mouseUp, xMouseDown])

    const handleMouseMove = useCallback((e) => {
    }, [mouseDown, mouseUp, xMouseDown])

    const siderCloser = (e) => {
        e.preventDefault();
        if(e.target === e.currentTarget) {
            toggleSettingsSider(false)
        }
    }

    const screenGoLeft = () => {
        setBounce('bounceInRight')
        if (pathname === '/me') {
            history.push('/home')
            return
        }
        if (pathname === '/home') {
            if (visitedUsers.list.length > 0) {
                history.push(`/users/${visitedUsers.list[visitedUsers.list.length-1]._id}`)
                return
            }
            else{
                history.push('/me')
                return
            }
        }
        if (pathname.indexOf('/users/') > -1) {
            const ID = pathname.split('/')[2]
            const userIndex = visitedUsers.list.findIndex(u => u._id == ID)
            if (userIndex > -1) {
                if (userIndex === 0) {
                    history.push('/me')
                    return
                }
                const nextID = visitedUsers.list[userIndex - 1]._id
                history.push(`/users/${nextID}`)
            } else {
                // If the user is not in the list of the visited users
                history.push('/home')
            }
        }
    }
    const screenGoRight = () => {
        setBounce('bounceInLeft')
        if (pathname === '/home') {
            history.push('/me')
            return
        }
        if (pathname === '/me') {
            if (visitedUsers.list.length > 0) {
                history.push(`/users/${visitedUsers.list[0]._id}`)
                return
            }
            return
        }
        if (pathname.indexOf('/users/') > -1) {
            const ID = pathname.split('/')[2]
            const userIndex = visitedUsers.list.findIndex(u => u._id == ID)
            if (userIndex > -1) {
                // If the user is the last in the list of the visited users
                if (userIndex === visitedUsers.list.length - 1) {
                    history.push('/home')
                    return
                }
                const nextID = visitedUsers.list[userIndex + 1]._id
                history.push(`/users/${nextID}`)
            } else {
                // If the user is not in the list of the visited users
                history.push('/home')
            }
        }
    }

    const handleMouseUp = useCallback((e) => {
        setMD(false);
        setMU(true);

        const xMouseUp = e.screenX;
        const goRight = xMouseDown - xMouseUp > 150
        const goLeft = xMouseDown - xMouseUp < -150
        if (goLeft) screenGoLeft()
        if (goRight) screenGoRight()

        setXMD(0);
    }, [mouseDown, mouseUp, xMouseDown])

    if (!localStorage.getItem('token') && !['/', '/home'].includes(pathname)) return <Redirect to="/login"/>
    if (!user.data._id && !['/', '/home'].includes(pathname)) return <div id="react-dashboard"/>

    return (
        <div
            tabIndex="0"
            onKeyDown={onKeyDown}
            onMouseUp={handleMouseUp}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            className="container-fluid react-dashboard" id="react-dashboard"
        >
            {
                !pre &&
                <div onClick={siderCloser} style={{width: "100%", height: "100%"}}>
                    {isAdmin && (
                        <a
                            onClick={() => toggleSettingsSider(false)}
                            style={{position: 'relative', left: '10px', top: '10px', zIndex:"3"}}
                            href={process.env.REACT_APP_ADMIN_PANEL}
                            className="admin-button"
                        >
                            <span className="btn btn-outline-light">Admin Panel</span>
                        </a>
                    )}
                    {
                        !localStorage.getItem('token') && (
                            <div>
                                <a
                                    style={{position: 'relative', left: '10px', top: '10px', marginRight: '10px', zIndex:"3"}}
                                    onClick={create}
                                    className="admin-button"
                                >
                                    <span className="btn btn-outline-light">Create Account</span>
                                </a>
                                <a
                                    style={{position: 'relative', left: '10px', top: '10px', zIndex:"3"}}
                                    onClick={login}
                                    className="admin-button"
                                >
                                    <span className="btn btn-outline-light">Login</span>
                                </a>
                            </div>
                        )
                    }
                    <Background/>
                    {
                        user.data._id ? pathname.includes("/users/")
                            ? <UserDisplay isShownAll={isShownAll}/>
                            : <MyDisplay isShownAll={isShownAll}/> : ''
                    }
                    {children}
                    <ControlContainer
                        toggleAll={toggleAll}
                        isShownAll={isShownAll}
                    />
                    <SiderOptions />
                </div>
            }
        </div>
    )
};

export default withRouter(connect(state => ({user: state.user, visitedUsers: state.visitedUsers}), {
    requestUser,
    requestPosts,
    requestGuestPosts,
    requestSavedPosts
})(UserDashboard));
