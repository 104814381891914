import axios from 'axios'
import decode from "jwt-decode";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

instance.interceptors.request.use((config) => {
  const apiToken = localStorage.getItem('token')
  if (apiToken) {
    const decoded = decode(apiToken);

    const currentTime = Date.now() / 1000;
    if(decoded.exp < currentTime) {
      localStorage.removeItem('token')
      window.location.href = '/login'
      return;
    }

    config.headers = {
      'Authorization': 'Bearer ' + apiToken,
    }
  }
  return config;
})

export default instance

