import React, {useState} from "react";
import Switch from "../../shared/Switch";
import Select from 'react-select'
import { useSelector } from 'react-redux'

const Text = ({submit, isUploading, setIsUploading}) => {
    const [isPublic, setIsPublic] = useState(true);
    const [text, setText] = useState("");
    const [category, setCategory] = useState("")
    const categories = useSelector(state => state.categories.list.map(category => category.name));

    const changePublic = () => {
        setIsPublic(!isPublic);
    };

    const textChange = e => {
        setText(e.target.value);
    };

    const submitForm = () => {
        if (!text || !category) return false;
        const data = new FormData();
        data.append("text_content", text);
        data.append("type", "text");
        data.append("category", category);
        data.append("title", "New Text Post");
        data.append("is_public", isPublic ? "1" : "0");
        setIsUploading(true);
        submit(data);
    };

    return (
        <div className="add-text-post post-fields">
            <div className="caption">
                <textarea
                    onChange={textChange}
                    placeholder="Enter text here"
                />
                <span className="corner-field bottom-left"/>
                <span className="corner-field top-right"/>
            </div>
            <div className="fields-group">
                <div className="fields-group__bottom">
                    <div className="fields-group__bottom__item">
                        <div className="category-select">
                            <span className="category-select__title">CATEGORY</span>
                            <Select
                                onChange={e => setCategory(e.value)}
                                placeholder="Select Category"
                                className="category-select-component"
                                options={categories.map(c => ({value: c, label: c}))}
                            />
                        </div>
                    </div>
                    <div className="fields-group__bottom__item">
                        <Switch checked={!isPublic} handleChange={changePublic}/>
                    </div>
                </div>
            </div>
            <div className="button-group">
                <button onClick={submitForm}>
                    POST
                    <span className="corner bottom-left"><i className="fas fa-chevron-up"/></span>
                </button>
            </div>
        </div>
    );
};

export default Text;
