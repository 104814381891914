import React, { useState, useEffect } from 'react'
import c from 'classnames'
import { connect } from 'react-redux'

const Preloader = ({ user }) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {

    if (user.finishedBootRequest) {
      setTimeout(() => {
        setHidden(true)
      }, 1000)
    }
  }, [user.finishedBootRequest])

  return (
    <div className={c("preloader", { "hidden": user.success, "out": hidden })}>
      <div className="preloader-wrapper">
        <div className="preloader-div" />
        <div className="preloader-logo" />
      </div>
    </div>
  )
}

export default connect(state => ({ user: state.user }))(Preloader)
