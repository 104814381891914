// Actions
import http from '../../utils/axios-interceptor'
import {AUTH_ERROR, AUTH_REQUEST, SIGN_OUT_REQUEST, SIGN_OUT_SUCCESS} from "./reducers";
import {SUCCESS_USER, ERROR_USER, REQUEST_USER, LOGOUT_USER, requestUserFollowing, requestUserAllPeople} from "../user/actions";
import { requestVisitedUsers } from "../visitedUsers/actions";
import {requestMyPosts} from "../myPosts/actions";
import { requestCategories } from "../categories/actions";

export const bootRequest = () => async dispatch => {
  dispatch({ type: AUTH_REQUEST })
  dispatch({type: REQUEST_USER})
  try {
    const res = await http.get('/api/user/me')
    dispatch({ type: SUCCESS_USER, payload: res.data.user })
    dispatch(requestVisitedUsers())
    dispatch(requestMyPosts());
    dispatch(requestUserFollowing())
    dispatch(requestUserAllPeople())
    dispatch(requestCategories())
  } catch (err) {
    dispatch({ type: AUTH_ERROR }, err)
    dispatch({ type: ERROR_USER, payload: err })
  }
}

export const requestLogin = ({ email, password }) => async dispatch => {
  dispatch({ type: AUTH_REQUEST })
  dispatch({type: REQUEST_USER})
  try {
    const res = await http.post('/api/auth/login', { email, password })
    localStorage.setItem('token', res.data.token)
    window.location.reload()
    dispatch({ type: SUCCESS_USER, payload: res.data.user })
  } catch (err) {
    dispatch({ type: AUTH_ERROR }, err)
    dispatch({ type: ERROR_USER, payload: err })
  }
}

export const requestRegister = ({ email, password, username, categories }) => async dispatch => {
  dispatch({ type: AUTH_REQUEST })
  dispatch({type: REQUEST_USER})
  try {
    const res = await http.post('/api/auth/signup', { email, password, username, categories })
    localStorage.setItem('token', res.data.token)
    window.location.reload()
    dispatch({ type: SUCCESS_USER, payload: res.data.user })
  } catch (err) {
    dispatch({ type: AUTH_ERROR }, err)
    dispatch({ type: ERROR_USER, payload: err })
  }
}

export const requestLogout = () => async dispatch => {
  const token = localStorage.getItem('token')
  if (token) {
    dispatch({ type: SIGN_OUT_REQUEST })
    localStorage.removeItem('token')
    dispatch({ type: SIGN_OUT_SUCCESS })
    dispatch({ type: LOGOUT_USER })
    window.location.href = '/login'
  }
}
