import {
  REQUEST_SAVED_POSTS,
  SUCCESS_SAVED_POSTS,
  ERROR_SAVED_POSTS,
  TOGGLE_SAVE_POST,
  TOGGLE_SAVED_POSTS,
  CLOSE_SAVED_POSTS
} from "./actions";
import {SUCCESS_ADD_COMMENTS} from "../comments/actions";

const initialState = {
  list: [],
  error: null,
  isShownSavedPost: false,
};

export default (state = initialState, action) => {
  const {isShownSavedPost} = state;
  const {type, payload} = action;
  switch (type) {
    case REQUEST_SAVED_POSTS:
      return {...state};
    case SUCCESS_SAVED_POSTS:
      return {...state, list: payload.reverse()};
    case ERROR_SAVED_POSTS:
      return {...state, error: payload};
    case TOGGLE_SAVE_POST:
      let savedPosts = [...state.list];
      const { post, isPostSaved } = payload;
      if (isPostSaved) {
        savedPosts = savedPosts.filter(p => p._id !== post._id)
      } else {
        savedPosts.push(post)
      }
      return { ...state, list: savedPosts };
    case SUCCESS_ADD_COMMENTS:
      return {
        ...state,
        list: [...state.list].map(p => {
          if (p._id === payload.post_id) {
            return { ...p, comments: p.comments.concat([payload]) }
          }
          return p
        })
      };
    case TOGGLE_SAVED_POSTS:
      return {...state, isShownSavedPost: !isShownSavedPost};
    case CLOSE_SAVED_POSTS:
      return {...state, isShownSavedPost: false};
    default:
      return state;
  }
}
