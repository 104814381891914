import http from "../../utils/axios-interceptor";

export const REQUEST_SAVED_POSTS = "REQUEST_SAVED_POSTS";
export const SUCCESS_SAVED_POSTS = "SUCCESS_SAVED_POSTS";
export const ERROR_SAVED_POSTS = "ERROR_SAVED_POSTS";
export const TOGGLE_SAVED_POSTS = "TOGGLE_SAVED_POSTS";
export const CLOSE_SAVED_POSTS = "CLOSE_SAVED_POSTS";
export const TOGGLE_SAVE_POST = "TOGGLE_SAVE_POST"

export const requestSavedPosts = () => async dispatch => {
    dispatch({ type: REQUEST_SAVED_POSTS })
    try {
        const res = await http.get('/api/posts/saved')
        dispatch({ type: SUCCESS_SAVED_POSTS, payload: res.data.posts })
    } catch (err) {
        dispatch({ type: ERROR_SAVED_POSTS }, err)
    }
}

export const toggleSavePost = (isPostSaved, post) => async dispatch => {
    const saveUrl = isPostSaved ? `/api/posts/unsave/${post._id}` : `/api/posts/save/${post._id}`;
    await http.post(saveUrl)
    dispatch({ type: TOGGLE_SAVE_POST, payload: { post, isPostSaved } })
}

export const toggleSavedPosts = () => ({
    type: TOGGLE_SAVED_POSTS
});

export const closeSavedPosts = () => ({
    type: CLOSE_SAVED_POSTS
});
