import React from 'react'
import {Provider} from 'react-redux'
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import store from './redux/store'
import '@fortawesome/fontawesome-free/css/all.css'
import '@brainhubeu/react-carousel/lib/style.css';
import './sass/app.scss'

import {Scroll} from './context/scroll'
import Main from './Main'
import {ModalContextComponent} from "./context/modal"
import {System} from "./context/system";

const options = {
    position: positions.BOTTOM_RIGHT,
    timeout: 3000,
    transition: transitions.FADE
}

export default () => {
    return (
        <Provider store={store}>
            <System>
                <ModalContextComponent>
                    <Scroll>
                        <AlertProvider template={AlertTemplate} {...options}>
                            <Main/>
                        </AlertProvider>
                    </Scroll>
                </ModalContextComponent>
            </System>
        </Provider>
    )
}
