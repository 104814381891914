import { REQUEST_USER_POSTS, SUCCESS_USER_POSTS, ERROR_USER_POSTS } from "./actions";
import {SUCCESS_ADD_COMMENTS} from "../comments/actions";

const initialState = {
  list: [],
  error: null,
  isShownSavedPost: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REQUEST_USER_POSTS:
      return { ...state };
    case SUCCESS_USER_POSTS:
      return { ...state, list: payload.reverse() };
    case SUCCESS_ADD_COMMENTS:
      return {
        ...state,
        list: [...state.list].map(p => {
          if (p._id === payload.post_id) {
            return { ...p, comments: p.comments.concat([payload]) }
          }
          return p
        })
      };
    case ERROR_USER_POSTS:
      return { ...state, error: payload };
    default:
      return state;
  }
}
