import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import { ScrollContext } from "../../context/scroll";
import BubblesList from "../../Components/Bubbles/BubblesList";
import {SystemContext} from "../../context/system";
import c from "classnames";

const HomeBubbleList = ({ posts: { list } }) => {
  const { toggleRun } = useContext(ScrollContext);
  const {  bounce } = useContext(SystemContext);

  useEffect(() => {
    toggleRun(true);
  }, []);

  return (
    <div className="bubbles-container">
      <div className={c(`bubbles-container__screen animated ${bounce}`)}>
        <BubblesList posts={list} />
      </div>
    </div>
  );
};

export default connect(state => ({ posts: state.posts }))(HomeBubbleList);
