import React from "react";
import NewPost from "./Content";

const NewLayoutodal = ({ closeModal }) => {
    return (
        <div id="currentModal"
             className="new-layout-modal"
             onClick={e => e.target.id === "currentModal" && closeModal()}
        >
            <NewPost closeModal={closeModal} />
        </div>
    );
};
export default NewLayoutodal
