import React, { useContext, useEffect, useRef, useState } from "react";
import { ScrollContext } from "../../context/scroll";
import { connect } from "react-redux";
import {SystemContext} from "../../context/system";

const Background = ({ user:{ data: { theme } }, themes: { themes: { solidThemes, gradientThemes, imageThemes, threeDTheme } } }) => {
    const { unAuthTheme } = useContext(SystemContext);
    const [curTheme, setCurTheme] = useState(theme || unAuthTheme)

    useEffect(() => {
        if (theme) {
            setCurTheme(theme)
        } else {
            setCurTheme(unAuthTheme)
        }
    }, [unAuthTheme, theme])

    const [backgroundSize, setBackgroundSize] = useState({
        backgroundSize: window.innerWidth > window.innerHeight
            ? "contain"
            : "cover"
    });
    const { translate } = useContext(ScrollContext);
    const animationTheme = useRef(null);

    const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame;
    const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame;

    useEffect(() => {
        const handleResize = () => {
            setBackgroundSize({
                backgroundSize: window.innerWidth > window.innerHeight
                    ? "contain"
                    : "cover"
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        return () => {
            if (animationTheme.current) {
                cancelAnimationFrame(animationTheme.current);
                animationTheme.current = undefined;
            }
        };
    }, []);

    useEffect(() => {
        const baseLayer = document.getElementById("background");
        const layer1 = document.getElementById("layer1");
        const layer2 = document.getElementById("layer2");
        const layer3 = document.getElementById("layer3");
        const layer4 = document.getElementById("layer4");
        const layer5 = document.getElementById("layer5");
        const layer6 = document.getElementById("layer6");
        const layer7 = document.getElementById("layer7");
        const layer8 = document.getElementById("layer8");
        const layer9 = document.getElementById("layer9");

        const allThemes = [...solidThemes, ...gradientThemes, ...imageThemes, ...threeDTheme];
        allThemes.forEach(theme => baseLayer.classList.remove(theme));
        baseLayer.classList.add(curTheme);
        if (curTheme === "theme-sky") {
            layer1.classList.add("theme-stars-1");
            layer2.classList.add("theme-stars-2");
            layer3.classList.add("theme-stars-3");
            layer4.classList.add("theme-stars-4");
            layer5.classList.add("theme-stars-5");
            layer6.classList.add("theme-stars-6");
            layer7.classList.add("theme-clouds-1");
            layer8.classList.add("theme-clouds-2");
            layer9.classList.add("theme-clouds-3");
        } else {
            layer1.classList.remove("theme-stars-1");
            layer2.classList.remove("theme-stars-2");
            layer3.classList.remove("theme-stars-3");
            layer4.classList.remove("theme-stars-4");
            layer5.classList.remove("theme-stars-5");
            layer6.classList.remove("theme-stars-6");
            layer7.classList.remove("theme-clouds-1");
            layer8.classList.remove("theme-clouds-2");
            layer9.classList.remove("theme-clouds-3");
        }
        if (curTheme === "theme-forest") {
            layer1.classList.add("theme-forest-1");
            layer2.classList.add("theme-forest-2");
            layer3.classList.add("theme-forest-3");
            layer4.classList.add("theme-forest-4");
        } else {
            layer1.classList.remove("theme-forest-1");
            layer2.classList.remove("theme-forest-2");
            layer3.classList.remove("theme-forest-3");
            layer4.classList.remove("theme-forest-4");
        }
    }, [curTheme]);

    useEffect(() => {
        const setOpacity = k => {
            let opacity;
            k >= 500 ? opacity = (1000 - k) / 500 : opacity = k / 500;
            return opacity;
        };

        const setZIndex = k => {
            let zIndex;
            k <= 250
                ? zIndex = -990
                : (k > 250 && k <= 500)
                ? zIndex = -989
                : k > 500 && k <= 750
                    ? zIndex = -987
                    : zIndex = -986;
            return zIndex;
        };

        const animateTheme = () => {
            const layer1 = document.getElementById("layer1");
            const layer2 = document.getElementById("layer2");
            const layer3 = document.getElementById("layer3");
            const layer4 = document.getElementById("layer4");
            const layer5 = document.getElementById("layer5");
            const layer6 = document.getElementById("layer6");
            const layer7 = document.getElementById("layer7");
            const layer8 = document.getElementById("layer8");
            const layer9 = document.getElementById("layer9");

            const k = ((translate + 1100) / 4) % 1000;

            if (curTheme === "theme-sky") {
                const k1 = k % 1000,
                    k2 = (k + 1000 / 6) % 1000,
                    k3 = (k + 2 * (1000 / 6)) % 1000,
                    k4 = (k + 3 * (1000 / 6)) % 1000,
                    k5 = (k + 4 * (1000 / 6)) % 1000,
                    k6 = (k + 5 * (1000 / 6)) % 1000,
                    k7 = (k + 1000 / 3) % 1000,
                    k8 = (k + 2 * (1000 / 3)) % 1000;

                layer1.style.transform = `translateZ(${k1 / 2}px)`;
                layer2.style.transform = `translateZ(${k2 / 2}px)`;
                layer3.style.transform = `translateZ(${k3 / 2}px)`;
                layer4.style.transform = `translateZ(${k4 / 2}px)`;
                layer5.style.transform = `translateZ(${k5 / 2}px)`;
                layer6.style.transform = `translateZ(${k6 / 2}px)`;
                layer7.style.transform = `translateZ(${k1 * 2}px)`;
                layer8.style.transform = `translateZ(${k7 * 2}px)`;
                layer9.style.transform = `translateZ(${k8 * 2}px)`;

                layer1.style.opacity = `${setOpacity(k1)}`;
                layer2.style.opacity = `${setOpacity(k2)}`;
                layer3.style.opacity = `${setOpacity(k3)}`;
                layer4.style.opacity = `${setOpacity(k4)}`;
                layer5.style.opacity = `${setOpacity(k5)}`;
                layer6.style.opacity = `${setOpacity(k6)}`;
                layer7.style.opacity = `${k1 / 200}`;
                layer8.style.opacity = `${k7 / 200}`;
                layer9.style.opacity = `${k8 / 200}`;

                layer7.style.zIndex = `${setZIndex(k1)}`;
                layer8.style.zIndex = `${setZIndex(k7)}`;
                layer9.style.zIndex = `${setZIndex(k8)}`;
            }

            if (curTheme === "theme-forest") {
                const k1 = k % 1000,
                    k2 = (k + 250) % 1000,
                    k3 = (k + 500) % 1000,
                    k4 = (k + 750) % 1000;
                layer1.style.transform = `translateZ(${k1 * 1.75}px)`;
                layer2.style.transform = `translateZ(${k2 * 1.75}px)`;
                layer3.style.transform = `translateZ(${k3 * 1.75}px)`;
                layer4.style.transform = `translateZ(${k4 * 1.75}px)`;
                layer1.style.opacity = `${k1 / 500}`;
                layer2.style.opacity = `${k2 / 500}`;
                layer3.style.opacity = `${k3 / 500}`;
                layer4.style.opacity = `${k4 / 500}`;
                layer1.style.zIndex = `${setZIndex(k1)}`;
                layer2.style.zIndex = `${setZIndex(k2)}`;
                layer3.style.zIndex = `${setZIndex(k3)}`;
                layer4.style.zIndex = `${setZIndex(k4)}`;
            }
        };
        animationTheme.current = requestAnimationFrame(() => animateTheme());
        return () => {
            if (animationTheme.current) {
                cancelAnimationFrame(animationTheme.current);
                animationTheme.current = undefined;
            }
        }
    }, [translate]);

    return (
        <>
            <div id="background" className="theme-base" />
            <div className="theme-container">
                <div id="layer1" style={backgroundSize} />
                <div id="layer2" style={backgroundSize} />
                <div id="layer3" style={backgroundSize} />
                <div id="layer4" style={backgroundSize} />
                <div id="layer5" style={backgroundSize} />
                <div id="layer6" style={backgroundSize} />
                <div id="layer7" style={backgroundSize} />
                <div id="layer8" style={backgroundSize} />
                <div id="layer9" style={backgroundSize} />
            </div>
        </>
    );
};

export default connect(state => ({ user: state.user, themes: state.themes }))(Background);
