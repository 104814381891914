import React, { createContext, useState } from "react";

export const SystemContext = createContext(true);
const SystemProvider = SystemContext.Provider;
export const SystemConsumer = SystemContext.Consumer;

export const System = ({ children }) => {
    const [to, setTO] = useState()
    const [unAuthTheme, setUnAuthTheme] = useState('theme-gradient__light-blue')
    const [settingsSider, toggleSettingsSider] = useState(false);
    const [bounce, setBounce] = useState('')

    const clearTO = () => {
        clearTimeout(to)
    }

    return (
        <SystemProvider
            value={{
                settingsSider,
                toggleSettingsSider,
                bounce,
                setBounce,
                unAuthTheme,
                setUnAuthTheme,
                clearTO,
                setTO
            }}
        >
            {children}
        </SystemProvider>
    );
};
