import { START_FETCH_CATEGORIES, SUCCESS_FETCH_CATEGORIES, ERROR_FETCH_CATEGORIES } from "./actionTypes";

const initialState = {
    list: [],
    error: null
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case START_FETCH_CATEGORIES:
            return {...state};
        case SUCCESS_FETCH_CATEGORIES:
            return {...state, list: payload};
        case ERROR_FETCH_CATEGORIES:
            return {...state, error: payload};
        default:
            return state;
    }
};