import React, {useEffect, useState} from "react";
import c from "classnames";
import Comment from "../Comment/Comment";
import "react-perfect-scrollbar/dist/css/styles.css";

const CommentsList = ({postId, posts, direction}) => {
    const [commentsClass, setCommentsClass] = useState(false)
    useEffect(() => {
        if (posts?.comments?.length >= 5) {
            setCommentsClass(true)
        }
    }, [posts]);

    return (
        <div className={c("comments__" + direction + "__list", {"scrollable-list": commentsClass})}>
            {posts.comments.map(comment => (
                comment.poster && <Comment
                    poster={comment.poster}
                    text={comment.text}
                    direction={direction}
                    key={comment._id}
                />
            ))}
        </div>
    );
};

export default CommentsList