import React, {useState, useEffect, useContext} from "react";
import {connect, useSelector} from "react-redux";
import { setTheme } from "../../../ducks/themes/actions";
import {requestUser} from "../../../ducks/user/actions";
import {SystemContext} from "../../../context/system";

const Item = ({ theme, themes: { activeTheme }, setTheme, requestUser }) => {
    const { setUnAuthTheme, unAuthTheme } = useContext(SystemContext);

    const updateTheme = () => {
        if (localStorage.getItem('token')) {
            setTheme(theme, requestUser)
        } else {
            setUnAuthTheme(theme)
        }
    }

    const compareTheme = () => {
        if (localStorage.getItem('token')) {
            return currentTheme === theme
        } else {
            return unAuthTheme === theme
        }
    }

    const currentTheme = useSelector(state => state?.user?.data?.theme)

    const themePreview = theme === "theme-sky"
        ? "theme-sky__preview"
        : theme === "theme-forest"
            ? "theme-forest__preview"
            : theme;

    return (
        <div className={`${themePreview} item`} onClick={updateTheme}>
            {compareTheme() && <i className="fa fa-check item__checked" />}
        </div>
    );
};
export default connect(state => ({ themes: state.themes }), { setTheme, requestUser })(Item);