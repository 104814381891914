import React from "react";
import c from "classnames";
import CommentForm from "./CommentForm/CommentForm";
import CommentsList from "./CommentsList/CommentsList";

const CommentsPopup = ({ visible, postId, posts, direction }) => {
    return (
        <div style={{ overflow: 'hidden' }} className={c("comments__"+direction, { "comments-active": visible })}>
            <CommentForm postId={postId} />
            <CommentsList postId={postId} posts={posts} direction={direction} />
        </div>
    );
};

export default CommentsPopup;