import React, {useContext, useEffect, useState} from "react";
import {withRouter} from "react-router-dom";
import {visitUser} from "../../../ducks/visitedUsers/actions";
import {ScrollContext} from "../../../context/scroll";
import {requestFindUsers} from "../../../ducks/search/actions"
import {connect} from "react-redux";

const SearchControl = ({history, location: {pathname}, search, user: {data}, requestFindUsers, posts: {list}, visitUser}) => {
    const [searchValue, setSearchValue] = useState("");
    const {setTranslate, stopLoop} = useContext(ScrollContext);

    useEffect(() => {
        return () => {
            setSearchValue("");
        };
    }, [pathname]);

    const onSearchChange = val => {
        setSearchValue(val);
        requestFindUsers(val)
    };

    const handleUserClick = async user => {
        visitUser(user);
        const userId = data._id
        await userId === user._id ? history.push("/me") : history.push(`/users/${user._id}`);
        setTranslate(-1000);
    };

    return (
        <div className="search-bar">
            {
                data._id &&
                <div>
                    <div className="search-bar__list">
                        {searchValue.length > 0 && search.list.map(user => (
                            <div className="search-bar__list__item"
                                 key={user._id}
                                 onClick={() => handleUserClick(user)}
                            >
                                {!user.img
                                    ? <i className="fa fa-user-circle"/>
                                    : <div style={{backgroundImage: `url(${user.img})`}}/>}
                                <span>{user.username}</span>
                            </div>
                        ))}
                    </div>
                    <div className="search-bar__form">
                        <div className="search-bar__form__input">
                            <input
                                type="text"
                                value={searchValue}
                                onChange={e => onSearchChange(e.target.value)}
                                placeholder="Search..."
                                autoComplete="off"
                            />
                        </div>
                        <div className="search-bar__form__icon">
                            <i className="fa fa-search"/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default withRouter(connect(state => ({user: state.user, posts: state.posts, search: state.search}), {
    visitUser,
    requestFindUsers
})(SearchControl));
