import http from "../../utils/axios-interceptor";

export const REQUEST_VISITED_USERS = "REQUEST_VISITED_USERS";
export const SUCCESS_VISITED_USERS = "SUCCESS_VISITED_USERS";
export const ERROR_VISITED_USERS = "ERROR_VISITED_USERS";
export const VISIT_USER = "VISIT_USER";
export const REMOVE_USER_FROM_LIST = "REMOVE_USER_FROM_LIST";

export const requestVisitedUsers = () => async dispatch => {
  dispatch({type: REQUEST_VISITED_USERS})
  try {
    const res = await http.get('/api/user/visited')
    dispatch({type: SUCCESS_VISITED_USERS, payload: res.data.users})
  } catch (err) {
    dispatch({type: ERROR_VISITED_USERS}, err)
  }
}

export const visitUser = (user) => async dispatch => {
  await http.post(`/api/user/visit/${user._id}`)
  dispatch({type: VISIT_USER, payload: { user }})
}

export const removeUserFromList = (id) => async dispatch => {
  await http.post(`/api/user/unvisit/${id}`)
  dispatch({
    type: REMOVE_USER_FROM_LIST,
    payload: { id }
  })
}
