import React, {useState} from 'react'
import { connect } from 'react-redux'
import { useAlert } from "react-alert";
import validateEmail from '../../utils/validate-email'
import {requestRegister} from "../../ducks/auth/actions";

const RegisterForm = ({ requestRegister, categories }) => {
  const alert = useAlert()
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setCPassword] = useState('')

  const register = e => {
    e.preventDefault();
    let valid = true;
    if (!email) {
      valid = false;
      alert.show('Email is required!')
    }
    if (email && !validateEmail(email)) {
      valid = false
      alert.show('Your email is incorrect!')
    }
    if (!password) {
      valid = false
      alert.show('Password is required!')
    }
    if (password && password !== confirmPassword) {
      valid = false;
      alert.show('Passwords do not match!')
    }
    if (!username) {
      valid = false
      alert.show('Username is required!')
    }

    if (valid)
      requestRegister({ email, password, username, categories })
  }

  return (
    <div className="col-lg-8 col-md-10 col-xs-12 d-flex d-none justify-content-center" id="register-main">
      <div className="bubble-form">
        <div className="card d-flex align-items-center" id="register">
          <div className="card-body">
            <div id="register-form">
              <div>
                <div className="form-group">
                  <label htmlFor="email" className=" text-md-right">Email</label>

                  <div className="">
                    <input autoFocus onChange={e => setEmail(e.target.value)} id="email" type="email" className="form-control" name="email" required />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label htmlFor="username" className=" col-form-label text-md-right">Username</label>

                  <div className="">
                    <input id="username" onChange={e => setUsername(e.target.value)} type="username" className="form-control" name="username"
                           autoComplete="new-username"/>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password" className=" col-form-label text-md-right">Password</label>

                  <div className="">
                    <input id="password" onChange={e => setPassword(e.target.value)} type="password" className="form-control" required/>
                  </div>
                </div>

                <div className="form-group">
                  <label htmlFor="password-confirm" className=" col-form-label text-md-right">
                    Confirm Password
                  </label>

                  <div className="">
                    <input id="password-confirm" type="password" className="form-control"
                           onChange={e => setCPassword(e.target.value)}
                           name="password_confirmation" required autoComplete="new-password"/>
                  </div>
                </div>

                <div className="form-group  d-flex justify-content-center mt-3">
                  <button onClick={e => register(e)} className="btn btn-secondary">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(null, { requestRegister })(RegisterForm)
