import {ERROR_POSTS, REQUEST_POSTS, SUCCESS_CREATE_POST, SUCCESS_DELETE_POST, SUCCESS_POSTS, SUCCESS_EDIT_FIELDS, SET_EDIT_POST} from "./actions";
import {SUCCESS_ADD_COMMENTS} from "../comments/actions";

const initialState = {
    loading: false,
    loaded: true,
    error: null,
    success: false,
    list: [],
    editPost: {}
};

export default (state = initialState, action) => {
    const {type, payload} = action;
    let updatedList = []
    let editPost = {}
    switch (type) {
        case REQUEST_POSTS:
            return {...state, loading: true, loaded: false, error: null, success: false};
        case SUCCESS_POSTS:
            const updatedPosts = state.list.concat(payload)
            return {...state, loading: false, loaded: true, error: null, success: true, list: updatedPosts};
        case SUCCESS_DELETE_POST:
            updatedList = [...state.list].filter(i => i._id !== payload.id)
            return {...state, list: updatedList}
        case SUCCESS_CREATE_POST:
            updatedList = [...state.list]
            updatedList.push(payload.post)
            return {...state, list: updatedList}
        case SUCCESS_EDIT_FIELDS:
            editPost = {...state.editPost, ...payload.post};
            updatedList = [...state.list]
            const indexOfPost = updatedList.findIndex(p => p._id === editPost._id)
            updatedList[indexOfPost] = editPost
            return { ...state, list: updatedList, editPost }
        case SET_EDIT_POST:
            return { ...state, editPost: payload.post }
        case SUCCESS_ADD_COMMENTS:
            return {
                ...state,
                list: [...state.list].map(p => {
                    if (p._id === payload.post_id) {
                        return { ...p, comments: p.comments.concat([payload]) }
                    }
                    return p
                })
            };
        case ERROR_POSTS:
            return {...state, loading: false, loaded: false, error: payload, success: false};
        default:
            return state;
    }
}
