import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import c from "classnames";
import Count from "./Count";
import UserLogo from "./UserLogo";
import { connect } from "react-redux";
import { toggleFollowUser, requestFollowingUsers } from '../../ducks/followingPosts/actions'
import { requestSavedPosts } from "../../ducks/savedPosts/actions";
import { requestUser } from "../../ducks/user/actions";
import { requestAnotherUser } from "../../ducks/anotherUserData/actions";
import { requestUserPosts } from "../../ducks/userPosts/actions";

const UserDisplay = ({ location: { pathname }, match: { params }, requestAnotherUser, requestUserPosts, posts: { success, list }, user: { data }, anotherUser, isShownAll, toggleFollowUser }) => {
    const id = pathname.split('/')[2];
    const isAlreadyFollowing = data.following.some(f => f === id)
    useEffect(() => {
        if (id) {
            requestAnotherUser(id)
            requestUserPosts(id)
        }
    }, [id])
    if (!anotherUser.data) return <div />;
    if (!anotherUser.data._id) return <div />;

    const follow = () => {
        const choice = isAlreadyFollowing ? 'unfollow' : 'follow'
        toggleFollowUser(choice, id, requestFollowingUsers);
    }

    return (
        <div className={c("user-display flex-column", { deactived: !isShownAll })}>
            <span onClick={follow} className="btn btn-sm btn-outline-light p-1 mb-1 user-display__info__btn">
                { isAlreadyFollowing ? 'unfollow' : 'follow' }
            </span>
            <div className="d-flex flex-row">
                <UserLogo img={anotherUser.data.img}/>
                <div className="user-display__info">
                    <h4>{anotherUser.data.username}</h4>
                    <div className="user-display__info__meta">
                        <Count count={list.length} countName="Posts" />
                        <Count count={anotherUser.data.saved_by_users} countName="Saves" />
                        <Count count={anotherUser.data.following.length} countName="Following" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(connect(state => ({ posts: state.userPosts, user: state.user, anotherUser: state.anotherUserData }), {
    toggleFollowUser,
    requestUser,
    requestFollowingUsers,
    requestSavedPosts,
    requestAnotherUser,
    requestUserPosts,
})(UserDisplay));
