import { REMOVE_VISITED_USER, SET_SHOWN_ITEM, SHOW_NEXT_ITEM, SHOW_PREV_ITEM, VISIT_TO_USER } from "./actions";

// const userId = window.Laravel.user ? window.Laravel.user.id : "";
const userId = "";

const initialState = {
    visited: JSON.parse(localStorage.getItem(`linetrav_visited_${userId}`)) || [],
    shownItem: 0,
    isShowVisited: false,
    firstShow: true
};

export default (state = initialState, action) => {
    const { visited, shownItem } = state;
    const { type, payload } = action;

    switch (type) {
        case VISIT_TO_USER: {
            if (visited.some(i => i.username === payload.username)) {return state;}
            else {
            const newVisited = visited.concat([payload]);
            localStorage.setItem(`linetrav_visited_${userId}`, JSON.stringify(newVisited));
            return { ...state, visited: newVisited }};
        }
        case REMOVE_VISITED_USER: {
            const filtered = visited.filter(i => i.user_id !== payload);
            const newShownItem = shownItem === (filtered.length) && shownItem !== 0 ? shownItem - 1 : shownItem;
            localStorage.setItem(`linetrav_visited_${userId}`, JSON.stringify(filtered));
            return { ...state, visited: filtered, shownItem: newShownItem };
        }
        case SHOW_NEXT_ITEM: {
            let nextItem = shownItem + 1;
            if (nextItem > visited.length - 1) nextItem = 0;
            return { ...state, shownItem: nextItem, isShowVisited: true };
        }
        case SHOW_PREV_ITEM: {
            let prevItem = shownItem - 1;
            if (prevItem < 0) prevItem = visited.length - 1;
            return { ...state, shownItem: prevItem, isShowVisited: true };
        }
        case SET_SHOWN_ITEM:
            return { ...state, shownItem: payload, isShowVisited: true, firstShow: false };
        default:
            return state;
    }
};
