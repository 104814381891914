import http from "../../utils/axios-interceptor";

export const REQUEST_MY_POSTS = "REQUEST_MY_POSTS";
export const SUCCESS_MY_POSTS = "SUCCESS_MY_POSTS";
export const ERROR_MY_POSTS = "ERROR_MY_POSTS";

export const requestMyPosts = () => async dispatch => {
  dispatch({ type: REQUEST_MY_POSTS })
  try {
    const res = await http.get('/api/posts/my-posts/')
    dispatch({ type: SUCCESS_MY_POSTS, payload: res.data.posts })
  } catch (err) {
    dispatch({ type: ERROR_MY_POSTS }, err)
  }
}
