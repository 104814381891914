import http from '../../utils/axios-interceptor'

export const REQUEST_POSTS = 'REQUEST_POSTS'
export const SUCCESS_POSTS = 'SUCCESS_POSTS'
export const ERROR_POSTS = 'ERROR_POSTS'

export const REQUEST_CREATE_POST = 'REQUEST_CREATE_POST'
export const SUCCESS_CREATE_POST = 'SUCCESS_CREATE_POST'
export const ERROR_CREATE_POST = 'ERROR_CREATE_POST'

export const REQUEST_DELETE_POST = 'REQUEST_DELETE_POST'
export const SUCCESS_DELETE_POST = 'SUCCESS_DELETE_POST'
export const ERROR_DELETE_POST = 'ERROR_DELETE_POST'

export const SET_EDIT_POST = 'SET_EDIT_POST'

export const REQUEST_EDIT_FIELDS = 'REQUEST_EDIT_FIELDS'
export const SUCCESS_EDIT_FIELDS = 'SUCCESS_EDIT_FIELDS'
export const ERROR_EDIT_FIELDS = 'ERROR_EDIT_FIELDS'

export const requestGuestPosts = (tick) => async dispatch => {
  dispatch({ type: REQUEST_POSTS })
  try {
    let res = await http.get(`/api/posts/guest?tick=${tick}`)
    dispatch({ type: SUCCESS_POSTS, payload: res.data.posts })
  } catch (err) {
    dispatch({ type: ERROR_POSTS }, err)
  }
}

export const requestPosts = (tick) => async dispatch => {
  dispatch({ type: REQUEST_POSTS })
  try {
    let res = await http.get(`/api/posts?tick=${tick}`)
    dispatch({ type: SUCCESS_POSTS, payload: res.data.posts })
  } catch (err) {
    dispatch({ type: ERROR_POSTS }, err)
  }
}

export const createPost = (postData, cb) => async dispatch => {
  dispatch({ type: REQUEST_CREATE_POST })
  try {
    const { data: { post } } = await http.post('/api/posts', postData)
    dispatch({ type: SUCCESS_CREATE_POST,  payload: { post } })
    cb()
  } catch (err) {
    dispatch({ type: ERROR_CREATE_POST }, err)
  }
}

export const chooseEditPost = (post) => dispatch => {
  dispatch({ type: SET_EDIT_POST, payload: { post } })
}

export const editPost = (id, fields, cb) => async dispatch => {
  dispatch({ type: REQUEST_EDIT_FIELDS })
  try {
    const { data: { post } } = await http.post('/api/posts/update/' + id, fields)
    dispatch({ type: SUCCESS_EDIT_FIELDS, payload: { post } })
    cb()
  } catch (err) {
    dispatch({ type: ERROR_EDIT_FIELDS }, err)
  }
}

export const deletePost = (id, cb) => async dispatch => {
  dispatch({ type: REQUEST_DELETE_POST })
  try {
    await http.delete('/api/posts/' + id)
    dispatch({ type: SUCCESS_DELETE_POST, payload: { id } })
    cb()
  } catch (err) {
    dispatch({ type: ERROR_DELETE_POST }, err)
  }
}


