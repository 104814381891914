import React, { useState } from "react";
import Resizer from "react-image-file-resizer";
import { connect } from "react-redux";
import { updateUser } from "../../../ducks/user/actions";

const ImageUploader = ({ closeModal, updateUser }) => {
    const [img, setImg] = useState();

    const changeImage = (event, degree = 0) => {
        const file = event ? event.target.files[0] : img;
        const type = file.type.split("/")[1];
        Resizer.imageFileResizer(
            file,
            1024,
            1024,
            type,
            100,
            degree,
            uri => setImg(uri),
            "blob"
        );
    };

    const sendImage = () => {
        const data = new FormData();
        data.append("imageFile", img);
        updateUser(data, closeModal);
    };

    return (
        <div id="currentModal"
             className="modal__wrapper"
             onClick={e => e.target.id === "currentModal" && closeModal()}
        >
            <div className="modal__container">
                <i className="fas fa-times modal__container-close"
                   onClick={closeModal}
                />
                <div className="image-uploader">
                    <div>
                        <label htmlFor="file" className="image-uploader__label">
                            Select Image
                        </label>
                        <input style={{ display: "none" }}
                               id="file"
                               type="file"
                               accept="image/*"
                               onChange={event => changeImage(event)}
                        />
                    </div>
                    {img && (
                        <>
                            <div className="image-uploader__container">
                                <img className="image-uploader__container__poster"
                                     src={URL.createObjectURL(img)}
                                     onLoad={event => URL.revokeObjectURL(event.target.src)}
                                     alt="poster"
                                />
                            </div>
                            <div className="image-uploader__control">
                                <i className="fas fa-undo image-uploader__control-rotate"
                                   onClick={() => changeImage(null, 270)}
                                />
                                <div className="image-uploader__label" onClick={sendImage}>
                                    Upload
                                </div>
                                <i className="fas fa-redo  image-uploader__control-rotate"
                                   onClick={() => changeImage(null, 90)}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default connect(null, { updateUser })(ImageUploader);
