import { REQUEST_ANOTHER_USER, SUCCESS_ANOTHER_USER, ERROR_ANOTHER_USER } from "./actions";

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    success: false,
    data: {},
    finishedBootRequest: false,
}

export default (state = initialState, action) => {
    const {type, payload} = action
    switch (type) {
        case REQUEST_ANOTHER_USER:
            return {...state, loading: true, loaded: false, error: null, success: false}
        case SUCCESS_ANOTHER_USER:
            return {...state, loading: false, loaded: true, error: null, success: true, data: payload, finishedBootRequest: true}
        case ERROR_ANOTHER_USER:
            return {...state, loading: false, loaded: false, error: payload, success: false, finishedBootRequest: true}

        default:
            return state
    }
}
