import React, {useState} from "react";
import { connect } from "react-redux";
import { updateUserCategories } from "../../../ducks/user/actions";
import { requestPosts } from "../../../ducks/posts/actions";
import { useSelector } from 'react-redux'
import c from "classnames";

const UpdateCategory = ({ closeModal, user: { data }, updateUserCategories, requestPosts }) => {
  const list = useSelector(state => state.categories.list.map(category => category.name));
  const [categories, toggle] = useState(data.categories || ["Food", "Gaming", "Sports", "Autos & Vehicles"])
  const isChosen = (value) => categories.some(c => c === value)

  const choose = (value) => {
    if (!localStorage.getItem('token')) { return }
    let copy = [...categories]
    if (!isChosen(value)) {
      copy.push(value)
    } else {
      copy = copy.filter(c => c !== value)
    }
    toggle(copy)
  }

  const updateCategories = () => {
    updateUserCategories(categories, () => {
      requestPosts()
      closeModal();
      window.location.reload();
    })
  }

  return (
    <div id="currentModal"
         className="modal__wrapper"
         onClick={e => e.target.id === "currentModal" && closeModal()}
    >
      <div className="modal__container modal__post">
        <div className="modal-content new-post__content">
          <div className="new-post__content__form" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ fontSize: '1.85em', marginBottom: "20px", fontWeight: 'bold', textAlign: 'center' }} className="option-name">Select Categories of Interest</div>
            <div className="categories-list" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {
                list.map(item => {
                  return (
                    <span
                      key={item}
                      onClick={() => choose(item)}
                      style={{ margin: "5px" }}
                      className={c("btn", isChosen(item) ? { 'btn-dark': true } : { 'btn-outline-dark': true } )}
                    >
                    { item }
                  </span>
                  )
                })
              }
            </div>
            <button disabled={!localStorage.getItem('token')} onClick={updateCategories} className="btn btn-success" style={{ marginTop: '20px' }}>
              Update Categories
            </button>
          </div>
        </div>
        <i className="fas fa-times modal__container-close"
           onClick={closeModal}
        />
      </div>
    </div>
  );
};
export default connect(state => ({ user: state.user }), { updateUserCategories, requestPosts })(UpdateCategory);
