import React, {useState} from "react";
import {useDropzone} from "react-dropzone";
import Resizer from "react-image-file-resizer";

export default React.memo(({fileChange}) => {
    const [image, setImage] = useState();

    const {getRootProps, getInputProps, open, inputRef} = useDropzone({
        multiple: false,
        accept: "image/*",
        noClick: true,
        noKeyboard: true,
        onDrop: acceptedFiles => setImage(acceptedFiles[0]),
        onDropAccepted: acceptedFiles => changeImage(acceptedFiles[0])
    });

    const thumb = (
        image
            ? (
                <div className="dropzone__item__thumb">
                    <img src={URL.createObjectURL(image)}
                         onLoad={event => URL.revokeObjectURL(event.target.src)}
                         alt="preview"/>
                </div>
            )
            : <div/>
    );

    const changeImage = (acceptedFile, degree = 0) => {
        const file = acceptedFile ? acceptedFile : image;
        const type = file.type.split("/")[1];
        Resizer.imageFileResizer(
            file,
            1024,
            1024,
            type,
            100,
            degree,
            uri => {
                setImage(uri);
                fileChange(uri);
            },
            "blob"
        );
    };

    return (
        <section className="dropzone__container">
            <div {...getRootProps({className: "dropzone__item"})}>
                <input {...getInputProps()} />
                <div className="dropzone__item__upload">
                    <div className="button-item" onClick={open}>Select Files</div>
                    {image
                        ? <div className="dropzone__item__upload__control">
                            <i
                                className='fas fa-undo dropzone__item__upload__control__icon'
                                onClick={() => changeImage(null, 270)}
                            />
                            <i
                                className='fas fa-redo dropzone__item__upload__control__icon'
                                onClick={() => changeImage(null, 90)}
                            />
                        </div>
                        : <div className="no-file-selected">No File Selected</div>
                    }
                </div>
            </div>
            {thumb}
        </section>
    );
});
