import { combineReducers } from "redux";
import posts from "./posts/redusers";
import user from "./user/redusers";
import visited from "./visited/reducers";
import savedPosts from "./savedPosts/redusers";
import themes from "./themes/reducers";
import search from "./search/reducers"
import anotherUserData from "./anotherUserData/redusers"
import userPosts from "./userPosts/reducers"
import myPosts from './myPosts/reducers'
import visitedUsers from './visitedUsers/reducers'
import comments from "./comments/reducers";
import categories from "./categories/reducers";

export const rootReducer = combineReducers({
  posts,
  user,
  visited,
  search,
  savedPosts,
  myPosts,
  themes,
  userPosts,
  visitedUsers,
  anotherUserData,
  comments,
  categories,
});
