export const VISIT_TO_USER = "VISIT_TO_USER";
export const REMOVE_VISITED_USER = "REMOVE_VISITED_USER";
export const SHOW_NEXT_ITEM = "SHOW_NEXT_ITEM";
export const SHOW_PREV_ITEM = "SHOW_PREV_ITEM";
export const SET_SHOWN_ITEM = "SET_SHOWN_ITEM";

export const visitToUser = user => ({
    type: VISIT_TO_USER,
    payload: { ...user }
});

export const removeVisitedUser = id => ({
    type: REMOVE_VISITED_USER,
    payload: id
});

export const showNextItem = () => ({
    type: SHOW_NEXT_ITEM
});

export const showPrevItem = () => ({
    type: SHOW_PREV_ITEM
});

export const setShownItem = ind => ({
    type: SET_SHOWN_ITEM,
    payload: ind
});
