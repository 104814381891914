import React, { useContext } from "react";
import { connect } from 'react-redux'
import { ScrollContext } from "../../../context/scroll";
import { updateUserData } from "../../../ducks/user/actions";

const Slider = ({ updateUserData }) => {
    const { speed, setSpeed } = useContext(ScrollContext);

    const saveSpeed = async () => {
       await updateUserData({ scrollSpeed: speed })
    }

    return (
        <div className="settings__speed">
            <div className="settings__speed__slider">
                <input type="range" min="1" max="100" onMouseUp={saveSpeed} value={speed} onChange={e => setSpeed(parseInt(e.target.value))} className="slider" id="myRange" />
            </div>
            <div className="settings__speed__text">
                {speed}
            </div>
        </div>
    );
};
export default connect(null, { updateUserData })(Slider);