import React, {useState} from "react";
import c from "classnames";
import Resizer from "react-image-file-resizer";
import {requestPosts, deletePost} from "../../../ducks/posts/actions";
import {connect} from "react-redux";
import {requestUser} from "../../../ducks/user/actions";
import {withRouter} from "react-router-dom"
import axios from 'axios';

const ExpandModal = ({ postData, user, visible, closeModal, requestPosts, deletePost }) => {
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [image, setImage] = useState(postData.content);
  const currentUser = user.data._id
  const gif = ''

  const contentObj = {
    image: () => (
      <img
        className="expand-modal__content__image"
        src={image}
        onLoad={event => image && URL.revokeObjectURL(event.target.src)}
        alt="Expanded Image"
      />
    ),
    news: () => (
      <img
        className="expand-modal__content__image"
        src={image}
        onLoad={event => image && URL.revokeObjectURL(event.target.src)}
        alt="Expanded Image"
      />
    ),
    text: () => (
      <div className="expand-modal__content__text">
        {postData.content ? postData.content : ""}
      </div>
    )
  };

  const confirmStyle = {
    opacity: visibleConfirm ? 1 : 0,
    zIndex: visibleConfirm ? 10000 : -10000
  };

  const onDeletePost = () => {
    deletePost(postData._id, closeModal);
    setVisibleConfirm(false);
  };

  const rotateImage = async degree => {
    const url = postData.content;
    const resp = !image || (image && typeof image === 'string') && await window.fetch(url);
    const file = image ? image : await resp.blob();
    const type = file.type.split("/")[1];
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      type,
      100,
      degree,
      uri => {
        // TODO: if need to add logic to updating post
        setImage(uri);
      },
      "blob"
    );

  };

  return (
    <>
      <div className="expand-modal__confirm" style={confirmStyle}>
        <div>
          <p>Are you sure you want to delete this post?</p>
        </div>
        <div>
          <button
            type="button" className="btn btn-primary"
            onClick={() => setVisibleConfirm(false)}
          >
            no
          </button>
          <button type="button" className="btn btn-danger" onClick={onDeletePost}>yes</button>
        </div>
      </div>
      <div key={image} id="expandedModal" className={c("expand-modal", {chosen: visible})}
           onClick={e => e.target.id === "expandedModal" && closeModal()}>
        <div className="expand-modal__content">
          {currentUser === postData.user_id && (
            <div className="expand-modal__content__icons">
              <i className="fas fa-trash-alt" onClick={() => setVisibleConfirm(true)}/>
              {postData.type !== 'text' && <i className="fas fa-undo" onClick={() => rotateImage(270)}/>}
              {postData.type !== 'text' && <i className="fas fa-redo" onClick={() => rotateImage(90)}/>}
            </div>
          )}
          <i className="fas fa-times expand-modal__content__mark" onClick={closeModal}/>
          {contentObj[postData.type] && contentObj[postData.type]()}
        </div>
      </div>
    </>
  );
};

export default withRouter(connect(state => ({user: state.user}), {requestPosts, requestUser, deletePost})(ExpandModal));
