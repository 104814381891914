import React, {useState} from "react";
import YouTube from 'react-youtube';
import { useSelector } from 'react-redux'
import FileUpload from "../../shared/FileUpload";
import parse from 'url-parse';
import queryString from 'query-string';
import Select from "react-select"
import Switch from "../../shared/Switch";
import parseYoutubeLink from '../../utils/parse-youtube-link';

const opts = {
    height: '190',
    width: '360',
    playerVars: { autoplay: 0 },
};

const Image = ({submit, isUploading, setIsUploading}) => {
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState("");
    const [postTitle, addPostTitle] = useState("");
    const [postContent, addPostContent] = useState("");
    const [isPublic, setIsPublic] = useState(true);
    const [isPlayerShown, setPlayerShown] = useState(false);
    const [videoId, setVideoId] = useState('');
    const categories = useSelector(state => state.categories.list.map(category => category.name));

    const fileChange = value => {
        setFile(value);
    };

    const previewVideo = () => {
        if (postContent.indexOf('youtube.com') === -1) return false
        const videoId = parseYoutubeLink(postContent)
        if (!videoId) return false
        setVideoId(videoId)
        setPlayerShown(true)
    }

    const changePublic = () => {
        setIsPublic(!isPublic);
    };

    const submitForm = () => {
        const data = new FormData();
        data.append("type", "video");
        data.append("title", postTitle);
        data.append("text_content", postContent)
        data.append("category", category || "empty");
        setIsUploading(true);
        submit(data);
    };

    const changePostTitle = e => addPostTitle(e.target.value);
    const changePostContent = e => addPostContent(e.target.value);

    return (
        <div className="add-image-post post-fields">
            {/*<FileUpload fileChange={fileChange} />*/}
            <div className="caption">
                <textarea
                    onChange={changePostTitle}
                    placeholder="Enter text here"
                />
                <span className="corner-field bottom-left"/>
                <span className="corner-field top-right"/>
            </div>
            <div className="caption">
                <textarea
                    onChange={changePostContent}
                    placeholder="Enter YouTube Link"
                    style={{ height: '50px' }}
                />
                <span className="corner-field bottom-left"/>
                <span className="corner-field top-right"/>
            </div>
            {
                postContent.length > 0 &&
                <div style={{ marginBottom: '5px' }}>
                    <button onClick={previewVideo}>
                        Preview
                    </button>
                </div>
            }
            {
                isPlayerShown &&
                <YouTube videoId={videoId} opts={opts} />
            }
            <div className="fields-group">
                <div className="fields-group__bottom">
                    <div className="fields-group__bottom__item">
                        <div className="category-select">
                            <span className="category-select__title">CATEGORY</span>
                            <Select
                                onChange={e => setCategory(e.value)}
                                placeholder="Select Category"
                                className="category-select-component"
                                options={categories.map(c => ({value: c, label: c}))}
                            />
                        </div>
                    </div>
                    <div className="fields-group__bottom__item">
                        <Switch checked={!isPublic} handleChange={changePublic}/>
                    </div>
                </div>
            </div>
            <div className="button-group">
                <button onClick={submitForm}>
                    POST
                    <span className="corner bottom-left"><i className="fas fa-chevron-up"/></span>
                </button>
            </div>
        </div>
    );
};

export default Image;
