import {
    REQUEST_VISITED_USERS,
    SUCCESS_VISITED_USERS,
    ERROR_VISITED_USERS,
    VISIT_USER,
    REMOVE_USER_FROM_LIST
} from "./actions";

const initialState = {
    list: [],
    error: null,
};

export default (state = initialState, action) => {
    const {type, payload} = action;
    let visitedUsers = []
    switch (type) {
        case REQUEST_VISITED_USERS:
            return {...state};
        case SUCCESS_VISITED_USERS:
            return {...state, list: payload.reverse()};
        case ERROR_VISITED_USERS:
            return {...state, error: payload};
        case VISIT_USER:
            visitedUsers = [...state.list];
            const isUserExist = visitedUsers.some(u => u.username === payload.user.username)
            if (!isUserExist) {
                visitedUsers.push(payload.user)
            }
            return {...state, list: visitedUsers};
        case REMOVE_USER_FROM_LIST:
            visitedUsers = [...state.list].filter(u => u._id !== payload.id)
            return {...state, list: visitedUsers}

        default:
            return state;
    }
}
