import React, { createContext, useState } from "react";

export const ScrollContext = createContext(true);
const ScrollProvider = ScrollContext.Provider;
export const ScrollConsumer = ScrollContext.Consumer;

export const Scroll = ({ children }) => {
    // Scroll of the bubbles list
    const [translate, setTranslate] = useState(-1000);
    // Speed of the scroll
    const [speed, setSpeed] = useState(50);
    // Is autoplay on
    const [isRunning, toggleRun] = useState(true);

    const startLoop = () => toggleRun(true);
    const stopLoop = () => toggleRun(false);

    return (
        <ScrollProvider
            value={{
                speed, setSpeed,
                startLoop, stopLoop,
                isRunning, toggleRun,
                translate, setTranslate,
            }}
        >
            {children}
        </ScrollProvider>
    );
};
