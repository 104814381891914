import http from '../../utils/axios-interceptor'

export const START_FETCH_COMMENTS = "START_FETCH_COMMENTS";
export const SUCCESS_FETCH_COMMENTS = "SUCCESS_FETCH_COMMENTS";
export const ERROR_FETCH_COMMENTS = "ERROR_FETCH_COMMENTS";

export const START_ADD_COMMENTS = "START_ADD_COMMENTS";
export const SUCCESS_ADD_COMMENTS = "SUCCESS_ADD_COMMENTS";
export const ERROR_ADD_COMMENTS = "ERROR_ADD_COMMENTS";

export const requestComments = (postId) => async dispatch => {
    dispatch({ type: START_FETCH_COMMENTS })
    try {
        const res = await http.get(`api/comments/post/` + postId)
        dispatch({ type: SUCCESS_FETCH_COMMENTS, payload: res.data.comments })
    } catch (err) {
        dispatch({ type: ERROR_FETCH_COMMENTS }, err)
    }
}

export const addComment = (data, postId) => async dispatch => {
    dispatch({ type: START_ADD_COMMENTS })
    try {
        const res = await http.post(`/api/comments/${postId}`, data)
        dispatch({ type: SUCCESS_ADD_COMMENTS, payload: res.data.comment })
    } catch (err) {
        dispatch({ type: ERROR_ADD_COMMENTS }, err)
    }
}
