import React from "react";
import Item from "./Item";
import { connect } from "react-redux";

const Themes = ({ themes: { themes: { solidThemes, gradientThemes, imageThemes, threeDTheme } } }) => {
    return (
        <div className="settings__themes">
            <span className="settings__text">Solid</span>
            <div className="settings__themes__list">
                {solidThemes.map(theme => <Item key={theme} theme={theme} />)}
            </div>
            <span className="settings__text">Gradient</span>
            <div className="settings__themes__list">
                {gradientThemes.map(theme => <Item key={theme} theme={theme} />)}
            </div>
            <span className="settings__text">Image</span>
            <div className="settings__themes__list">
                {imageThemes.map(theme => <Item key={theme} theme={theme} />)}
            </div>
            <span className="settings__text">3d</span>
            <div className="settings__themes__list">
                {threeDTheme.map(theme => <Item key={theme} theme={theme} />)}
            </div>
        </div>
    );
};
export default connect(state => ({ themes: state.themes }))(Themes);