import React, { useContext, useState } from "react";
import { ScrollContext } from "../../../context/scroll";
import { Link, withRouter } from "react-router-dom";
import Control from "./Control";
import { connect } from "react-redux";
import { closeSavedPosts } from "../../../ducks/savedPosts/actions";
import {SystemContext} from "../../../context/system";

const LeftControls = ({ isShownAll, toggleAll, savedPosts: { isShownSavedPost }, location: {pathname}, closeSavedPosts }) => {
    const [isFull, setFull] = useState(false)
    const { isRunning, toggleRun, setTranslate } = useContext(ScrollContext);
    const { toggleSettingsSider } = useContext(SystemContext);

    const openFullscreen = () => {
        if (isFull) {
            setFull(false)
            document.exitFullscreen()
        } else {
            setFull(true)
            document.body.requestFullscreen()
        }
    }

    const handleHomeClick = () => {
        setTranslate(-1000);
        isShownSavedPost && closeSavedPosts();
    };

    return (
        <div onClick={() => toggleSettingsSider(false)} className="controls__group left">
            <Control controlIcon={isShownAll ? "minus" : "plus"} onClick={() => toggleAll()} />
            {isShownAll && (
                <>
                    <Control controlIcon={isFull ? "compress" : "expand"} onClick={() =>  openFullscreen()} />
                    <Control controlIcon={isRunning ? "pause" : "play"}
                             onClick={() => toggleRun(!isRunning)} />
                    <Link to="/home">
                        <Control active={pathname === '/home'} controlIcon="home" onClick={handleHomeClick} />
                    </Link>
                </>
            )}
        </div>
    );
};

export default withRouter(connect(state => ({ savedPosts: state.savedPosts }), { closeSavedPosts })(LeftControls));
