import React from 'react'
import Carousel, {arrowsPlugin} from '@brainhubeu/react-carousel';
import {chunkArray} from "../../../utils/chunk-array";
import {useDispatch, useSelector} from "react-redux";
import {requestUserFollowing} from "../../../ducks/user/actions";
import {toggleFollowUser} from "../../../ducks/followingPosts/actions";

export default ({onlyMine}) => {
    const dispatch = useDispatch()
    const {data, followingUsers = [], allPeople = []} = useSelector(state => state.user)
    let list = onlyMine ? followingUsers.map(u => ({_id: u._id, img: u.img, username: u.username})) : allPeople
    list = list.filter(u => u._id.toString() !== data._id.toString())

    const chunks = chunkArray(list, 28)

    const toggleFollow = (userId) => {
        const choice = followingUsers.some(u => u._id.toString() === userId) ? 'unfollow' : 'follow'
        dispatch(toggleFollowUser(
            choice,
            userId,
            () => dispatch(requestUserFollowing())
        ))
    }

    return (
        <div style={{width: "540px", margin: "auto"}}>
            <div className="total-title">
                [<span>{list.length}</span>]
            </div>
            <Carousel
                plugins={[
                    {
                        resolve: arrowsPlugin,
                        options: {
                            arrowLeft: <i className="fas fa-angle-left" style={{ fontSize: '30px', color: '#FFF', cursor: 'pointer' }}/>,
                            arrowLeftDisabled:<i className="fas fa-angle-left" style={{ fontSize: '30px', color: '#383838', cursor: 'pointer' }}/>,
                            arrowRight: <i className="fas fa-angle-right" style={{ fontSize: '30px', color: '#FFF', cursor: 'pointer' }} />,
                            arrowRightDisabled: <i className="fas fa-angle-right" style={{ fontSize: '30px', color: '#383838', cursor: 'pointer' }} />,
                            addArrowClickHandler: true,
                        }
                    }
                ]}
            >
                {
                    chunks.map((chunk, i) => {
                        return (
                            <div key={i} style={{display: 'flex', alignItems: 'flex-start', width: '100%', flexWrap: 'wrap' }}>
                                {
                                    chunk.map((item) => {
                                        const selected = !onlyMine && followingUsers.some(u => u._id.toString() === item._id.toString())
                                        const username = item.username.length > 10 ? item.username.slice(0, 10) + '...' : item.username

                                        return (
                                            <div key={item._id} className="circle-item" style={{
                                                flexBasis: '13%',
                                                opacity: selected ? 0.35 : 1,
                                                cursor: 'pointer',
                                                padding: '2px'
                                            }}>
                                                <div
                                                    className="image"
                                                    onClick={() => toggleFollow(item._id.toString())}
                                                >
                                                    <img style={{width: '45px', height: '45px', borderRadius: '50%'}}
                                                         src={item.img} alt="User's Logo"/>
                                                </div>
                                                <span className="text">{username}</span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )
                    })
                }
            </Carousel>
        </div>
    )
}