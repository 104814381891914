import React, { useState } from "react";
import { addComment } from "../../../ducks/comments/actions";
import { useDispatch } from "react-redux";

const CommentForm = ({ postId }) => {

    const dispatch = useDispatch()

    const [commentText, addCommentText] = useState("");

    const commentTextChange = e => {
        addCommentText(e.target.value);
    };

    const commentHandler = (event) => {
        event.preventDefault()
        dispatch(addComment({ text: commentText}, postId))
            .then(() => addCommentText(''))
    }

    return (
        <div className={"comment-form"} onClick={(e) => e.stopPropagation()}>
            <div
                className="comments__comment-section__add-comment">
                <form onSubmit={commentHandler} className="add-comment">
                    <input className="search" type="text" value={commentText} onChange={commentTextChange}
                           placeholder="Add Comment..."/>
                    <button  className="lt-add-comment-icon ml-2">
                        <i className="fas fa-plus"/>
                    </button>
                </form>
            </div>
        </div>
    );
}



export default CommentForm;