import React, {useContext, useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {ScrollContext} from "../../../context/scroll";
import {ModalContext} from "../../../context/modal";
import c from "classnames";
import {connect, useDispatch} from "react-redux";
import { visitUser } from "../../../ducks/visitedUsers/actions";
import {toggleSavePost} from "../../../ducks/savedPosts/actions";
import {requestUser} from "../../../ducks/user/actions";
import CommentsPopup from "../../Comments/CommentsPopup";
import EditCaptionModal from "../../Modals/EditCaptionModal/EditCaptionModal";

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
const Bubble = (
  {
    post,
    index, savedPosts: {list},
    user, visitUser,
    toggleSavePost,
  }) => {
  const bubble = useRef(null);
  const {translate} = useContext(ScrollContext);
  const {expandPost} = useContext(ModalContext);
  const [isSetUp, setUp] = useState(false);
  const [tr, updateTranslate] = useState({});
  const [visible, toggle] = useState(false);
  const [far, isFar] = useState(true);
  const [commentsVisibility, toggleCommentsVisibility] = useState(false);
  const [editCaptionVisibility, toggleEditCaptionVisibility] = useState(false);
  const [title, setTitle] = useState(post.title)
  const [cont, setCont] = useState(post.content)

  const isPostSaved = list.some(i => i._id === post._id);

  useEffect(() => {
    const side = index % 2 === 0 ? -1 : 1;
    updateTranslate({
      x: getRandomInt(150, 300) * side + "%",
      y: getRandomInt(-50, 200) * 1 + "%",
      z: 200 * (index + 1) * -3 + "px"
    });
    setUp(true);
  }, []);

  useEffect(() => {
    let transform = bubble.current.style.transform;
    if (transform) {
      transform = transform.split(" ")[2].replace(")", "");
      toggle(Math.abs(parseFloat(transform)) - translate < 1500);
      isFar(Math.abs(parseFloat(transform)) - translate > 3500);
    }
  }, [isSetUp, translate]);

  const titleHandler = (editedTitle) => {
    if (post.type === "text") setCont(editedTitle)
    else setTitle(editedTitle)
  }

  const handleLink = () => user.data._id === post.user_id
    ? "/me"
    : `/users/${post.user_id}`;

  const userClick = () => {
    user.data._id !== post.poster[0]._id && visitUser(post.poster[0]);
  };

  const bubbleBackground = post.type !== 'text'
    ? {backgroundImage: `url(${cont })`}
    : {backgroundColor: "#ffffff"};

  return (
    <div
      style={{
        transform: `translate3D(
              ${tr.x || "0px"}, ${tr.y || "0px"}, ${tr.z || "0px"}
            )`
      }}
      className={c("bubble", {close: !far})}
      ref={bubble}
    >
      {
        isSetUp && (
          <>
            <div className="bubble__around">
              <Link to={handleLink} onClick={userClick}>
                {!post.poster[0].img
                  ? <i className="fa fa-user-circle bubble__around__icon"/>
                  : <div className="bubble__around__small"
                         style={{backgroundImage: `url(${post.poster[0].img})`}}/>
                }
              </Link>
              <div className={c("bubble__around__actions", {visible})}>
                {user.data._id === post.user_id ?
                  <i className={"fas fa-edit"}
                   onClick={() => toggleEditCaptionVisibility(!editCaptionVisibility)}/>
                   : null
                }
                <i className={`${isPostSaved ? "fa" : "far"} fa-bookmark`}
                   onClick={() => toggleSavePost(isPostSaved, post)}/>
                <i className={"far fa-comment"}
                   onClick={() => toggleCommentsVisibility(!commentsVisibility)}/>
              </div>
              {index % 2 === 0 ?
               <CommentsPopup visible={commentsVisibility} postId={post._id} posts={post} direction={"right"}/>:
               <CommentsPopup visible={commentsVisibility} postId={post._id} posts={post} direction={"left"}/>
              }
              <EditCaptionModal titleHandler={titleHandler} visible={editCaptionVisibility} post={post}/>
            </div>

            <div className={c("bubble__text", {visible})}>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.3)' }} className="bubble__text__description">
                <h3>
                  {
                    post.type === "news"
                      ? <span onClick={() => window.open(post.url, '_blank')} dangerouslySetInnerHTML={{__html: title}}/>
                      : post.poster[0].username
                  }
                </h3>
                <p>
                  {
                    post.type === 'image'
                        ? <span dangerouslySetInnerHTML={{__html: title}}/>
                        : null
                  }
                </p>
              </div>
            </div>

            <div
              className="bubble__background"
              style={bubbleBackground}
              onClick={() => expandPost(post)}
            >
              {post.type === 'text' && cont}
            </div>
          </>
        )
      }
    </div>
  );
};

export default connect(state => ({savedPosts: state.savedPosts, user: state.user}), {
  visitUser,
  requestUser,
  toggleSavePost,
})(Bubble);

