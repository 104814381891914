import { REQUEST_MY_POSTS, SUCCESS_MY_POSTS, ERROR_MY_POSTS } from "./actions";
import {SUCCESS_CREATE_POST, SUCCESS_DELETE_POST} from "../posts/actions";
import {SUCCESS_ADD_COMMENTS} from "../comments/actions";

const initialState = {
  list: [],
  error: null,
  isShownSavedPost: false,
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  let updatedList = []
  switch (type) {
    case REQUEST_MY_POSTS:
      return { ...state };
    case SUCCESS_MY_POSTS:
      return { ...state, list: payload };
    case SUCCESS_DELETE_POST:
      updatedList = [...state.list].filter(i => {
        return i._id !== payload.id
      })
      return { ...state, list: updatedList }
    case SUCCESS_CREATE_POST:
      updatedList = [...state.list]
      updatedList.unshift(payload.post)
      return { ...state, list: updatedList }
    case SUCCESS_ADD_COMMENTS:
      return {
        ...state,
        list: [...state.list].map(p => {
          if (p._id === payload.post_id) {
            return { ...p, comments: p.comments.concat([payload]) }
          }
          return p
        })
      };
    case ERROR_MY_POSTS:
      return { ...state, error: payload };
    default:
      return state;
  }
}
