import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {useAlert} from "react-alert";
import {Link} from "react-router-dom";
import {requestLogin} from "../../ducks/auth/actions";

const LoginPage = ({requestLogin}) => {
  const alert = useAlert()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const login = e => {
    e.preventDefault();
    let valid = true
    if (!email) {
      valid = false
      alert.show('Email is required!')
    }
    if (!password) {
      valid = false
      alert.show('Password is required!')
    }
    if (valid)
      requestLogin({email, password})
  }

  return (
    <React.Fragment>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-6 offset-3 col-sm-4 offset-sm-4 col-xs-4 offset-xs-4">
            <div id="logo" className="mb-3">
              <a href="/"></a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center" style={{overflow: 'hidden', position: 'relative'}}>
          <div className="col-lg-8 col-md-10 col-xs-12 d-flex justify-content-center">
            <div className="bubble-form">
              <div className="card d-flex align-items-center" style={{ position: 'relative' }}>
                <div className="floated first"><span>3d</span><b>social media.</b></div>
                <div className="floated second"><span>Always</span> <b>private.</b></div>
                <div className="floated third"><span>Always</span> <b>simple.</b></div>
                <div className="floated fourth"><span>Social media</span><b>refined.</b></div>
                <div className="card-body">
                  <div>
                    <div className="form-group">
                      <label htmlFor="email" className="col-form-label">Email</label>

                      <div className="">
                        <input
                          id="email" onChange={e => setEmail(e.target.value)} type="email" value={email}
                          className="form-control" name="email"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label className="col-form-label">Password</label>

                      <div className="">
                        <input
                          id="password" type="password" className="form-control" name="password"
                          onChange={e => setPassword(e.target.value)} value={password}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <div className=" ">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="remember" id="remember"/>

                          <label className="form-check-label" htmlFor="remember">
                            Remember Me
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className=" form-group mb-5 mt-1 d-flex justify-content-center">
                      <button onClick={login} className=" btn btn-secondary">
                        Login
                      </button>
                    </div>
                    <div className=" form-group my-1 d-flex justify-content-center">
                      <Link className=" naked-link" to="/register">+ Create Account</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ color: '#FFF', fontWeight: 600, marginTop: '100px', textAlign: 'center' }}>
          <a>
            <div>CONTACT</div>
          </a>
          <p>
            <small>copyright 2020 &copy; LineTrav Inc</small>
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default connect(null, {requestLogin})(LoginPage);
