import React, { useContext, useEffect } from "react";
import { ModalContext } from "../../context/modal";
import CreateModal from "./CreatePostModal";
import ExpandModal from "./ExpandModal";
import ExpandModalVideo from './ExpandModalVideo';
import UploaderModal from "./UploaderModal";
import UpdateCategory from './UpdateCategory'
import SignUpModal from './SignUpModal'
import { withRouter } from "react-router-dom";
import {ScrollContext} from "../../context/scroll";
import {SystemContext} from "../../context/system";
import NewLayoutModal from "./NewLayoutModal";

const Modals = ({ history, location }) => {
    const {
        modalType, setModalType,
        content, setContent,
        postType,
        postData
    } = useContext(ModalContext);
    const { startLoop } = useContext(ScrollContext)
    const { setTO } = useContext(SystemContext)

    useEffect(() => {
        return () => {
            if (history.action === "POP") {
                closeModal();
            }
        };
    }, [location.pathname]);

    const closeModal = (toHideModal) => {
        if (!localStorage.getItem('token')) {
            if (modalType === 'signup' && toHideModal) {
                setTO(
                    setTimeout(() => {
                        setModalType('signup')
                        startLoop()
                    }, 30000)
                )
            }
        }
        setModalType("");
        setTimeout(() => setContent(""), 300);
    };

    const modals = {
        "create-post": () => {
            return <CreateModal closeModal={closeModal} />
        },
        "expand-modal": () => (
            <ExpandModal
                postData={postData}
                postType={postType}
                content={content}
                closeModal={closeModal}
                visible={modalType === "expand-modal"}
            />
        ),
        "expand-modal-video": () => (
            <ExpandModalVideo
                postData={postData}
                postType={postType}
                content={content}
                closeModal={closeModal}
                visible={modalType === 'expand-modal-video'}
            />
        ),
        "load-image": () => <UploaderModal closeModal={closeModal} />,
        "update-category": () => <UpdateCategory closeModal={closeModal} />,
        "signup": () => <SignUpModal closeModal={closeModal} />,
        "new-layout-modal": () => <NewLayoutModal closeModal={closeModal} />
    };
    return modalType && modals[modalType]();
};

export default withRouter(Modals);
