import http from "../../utils/axios-interceptor";

export const REQUEST_SET_THEME = 'REQUEST_SET_THEME'
export const SUCCESS_SET_THEME = 'SUCCESS_SET_THEME'
export const ERROR_SET_THEME = 'ERROR_SET_THEME'

export const setTheme = (theme, cb) => async dispatch => {
    dispatch({ type: REQUEST_SET_THEME })
    try {
        const res = await http.post('/api/user/theme', { theme: theme })
        dispatch({ type: SUCCESS_SET_THEME })
        cb()
    } catch (err) {
        dispatch({ type: ERROR_SET_THEME }, err)
    }
}
