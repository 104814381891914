import React from "react"
import Switch from "react-switch"

class SwitchComponent extends React.Component {
    render() {
        const {checked, handleChange} = this.props
        return (
            <label className="react-switch-container" htmlFor="react-switch">
                <span className="react-switch-container__choice--left">PUBLIC</span>
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    onColor="#17cfd1"
                    offColor="#efefef"
                    onHandleColor="#fff"
                    offHandleColor="#fff"
                    handleDiameter={21}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    width={48}
                    height={25}
                    className="react-switch"
                    id="react-switch"
                />
                <span className="react-switch-container__choice--right">PRIVATE</span>
            </label>
        )
    }
}

export default SwitchComponent