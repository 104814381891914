import React, { createContext, useState } from "react";

export const ModalContext = createContext(true);
const ModalProvider = ModalContext.Provider;
export const ModalConsumer = ModalContext.Consumer;

export const ModalContextComponent = ({ children }) => {
    const [modalType, setModalType] = useState("");
    const [content, setContent] = useState("");
    const [postId, setPostId] = useState(null);
    const [postData, setPostData] = useState(null);

    const expandPost = (post = {}) => {
        setPostData(post)
        setModalType("expand-modal");
    };

    const openVideo = (post = {}) => {
        setPostData(post)
        setModalType("expand-modal-video")
    }

    const deletePost = id => {
        setModalType("confirm_modal");
        setPostId(id);
    };

    return (
        <ModalProvider
            value={{
                modalType, setModalType,
                content, setContent,
                expandPost, deletePost,
                postId, postData,
                openVideo
            }}
        >
            {children}
        </ModalProvider>
    );
};
