export const moduleName = "auth"

export const AUTH_REQUEST = `${moduleName}/SIGN_IN_REQUEST`
export const AUTH_ERROR = `${moduleName}/SIGN_IN_ERROR`
export const AUTH_SUCCESS = `${moduleName}/SIGN_IN_SUCCESS`
export const SIGN_OUT_REQUEST = `${moduleName}/SIGN_OUT_REQUEST`
export const SIGN_OUT_SUCCESS = `${moduleName}/SIGN_OUT_SUCCESS`

// State
const initial = {
  fetching: false,
  fetched: true,
  error: null,
  success: false,
  message: false
}

// Reducer
export default (state = initial, action) => {
  const { type, payload } = action
  switch (type) {

    case AUTH_REQUEST:
      return { ...state, fetching: true, fetched: false, error: false, success: false, message: null }
    case AUTH_SUCCESS:
      return { ...state, fetching: false, fetched: true, error: false, success: true, message: payload.message }
    case AUTH_ERROR:
      return { ...state, fetching: false, fetched: true, error: true, success: false, message: payload.message }

    case SIGN_OUT_REQUEST:
      return { ...state, fetching: true, fetched: false, error: false, success: false, message: null }
    case SIGN_OUT_SUCCESS:
      return { ...state, fetching: false, fetched: true, error: false, success: false, message: null }

    default:
      return state
  }
}

