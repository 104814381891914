import React from "react";
import {Route, Redirect} from "react-router-dom";

export default ({ component: Component, user, ...options }) => {
  return (
    <Route
      {...options}
      render={props => {
        return !localStorage.getItem('token')
          ? <Component {...props} />
          : <Redirect to="/home" />
      }}
    />
  )
};
