import React, { useState } from "react";
import { chooseEditPost, editPost } from "../../../ducks/posts/actions";
import { useDispatch } from "react-redux";
import c from "classnames";

const EditCaptionModal = ({ titleHandler, visible, post }) => {

    const dispatch = useDispatch()

    const [caption, editCaption] = useState("");

    const commentTextChange = e => {
        editCaption(e.target.value);
    };

    const commentHandler = (event) => {
        event.preventDefault()
        dispatch(chooseEditPost(post))
        dispatch(editPost(post._id,post.type === "text" ? { content: caption}: {title: caption} ))
            .then(titleHandler(caption))
            .then(() => editCaption(''))

    }

    return (
            <div style={{ marginTop: '20px' }} className={c("edit-caption", { "edit-active": visible })}>
                <form className="edit-form">
                    <input className="search" type="text" value={caption} onChange={commentTextChange}
                           placeholder="Here you can edit your caption"/>
                    <button onClick={commentHandler}>
                        <i className="fas fa-plus"/>
                    </button>
                </form>
            </div>

    );
}



export default EditCaptionModal;