import { START_FETCH_COMMENTS, SUCCESS_FETCH_COMMENTS, ERROR_FETCH_COMMENTS } from "./actions";

const initialState = {
    list: [],
    error: null
}

export default (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case START_FETCH_COMMENTS:
            return {...state};
        case SUCCESS_FETCH_COMMENTS:
            return {...state, list: payload.reverse()};
        case ERROR_FETCH_COMMENTS:
            return {...state, error: payload};
        default:
            return state;
    }
};