import React, { useContext } from "react";
import { ModalContext } from "../../../context/modal";
import Control from "./Control";
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux";
import { requestSavedPosts, toggleSavedPosts } from "../../../ducks/savedPosts/actions";
import {SystemContext} from "../../../context/system";

const RightControls = ({ history }) => {
    const { setModalType } = useContext(ModalContext);
    const {
        settingsSider,
        toggleSettingsSider
    } = useContext(SystemContext);

    return (
        <>
            <div className="controls__group right">
                { localStorage.getItem('token') && <Control controlIcon={"cubes"} onClick={() => {setModalType("new-layout-modal");toggleSettingsSider(false)}} /> }
                { localStorage.getItem('token') && <Control controlIcon={"plus"} onClick={() => {setModalType("create-post");toggleSettingsSider(false)}} /> }
                { localStorage.getItem('token') && <Control controlIcon={"save"} onClick={() => {history.push('/me/favourites');toggleSettingsSider(false)}} /> }
                <Control className="settings-icon" controlIcon={"cog"} onClick={() => toggleSettingsSider(!settingsSider)} />
            </div>
        </>
    );
};

export default withRouter(connect(state => ({ savedPosts: state.savedPosts }), {
    requestSavedPosts,
    toggleSavedPosts
})(RightControls));
