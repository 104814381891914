import React from "react";
import NewPost from "./NewPost";

const CreatePostModal = ({ closeModal }) => {
    return (
        <div id="currentModal"
             className="add-post-modal"
             onClick={e => e.target.id === "currentModal" && closeModal()}
        >
            <NewPost closeModal={closeModal} />
        </div>
    );
};
export default CreatePostModal;
