import React, {useEffect, useState} from 'react'
import c from 'classnames'
import useWindowDimensions from "../../utils/useWindowDimensions";
import { useSelector, useDispatch } from 'react-redux'
import { requestCategories } from "../../ducks/categories/actions";

const CategoriesSelect = ({ categories, choose, isChosen, setStep }) => {
  const { height, width } = useWindowDimensions();
  const [buttonClass, setButtonClass] = useState("")
  const [titleFontSize, setTitleFontSize] = useState("1.85em")
  const list = useSelector(state => state.categories.list.map(category => category.name));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestCategories())
  }, []);

  useEffect(() => {
    height <= 768 ? setButtonClass("btn-sm") : setButtonClass("");
    if (width <= 1024) {
      setButtonClass("btn-sm");
      setTitleFontSize("1.3em")
    }else {
      setTitleFontSize("1.85em")
    }
  }, [height, width])


  return (
    <div className="col-lg-8 col-md-10 col-xs-12 d-flex d-none justify-content-center" id="register-main">
      <div className="bubble-form" style={{ width: '880px' }}>
        <div className="card d-flex align-items-center" id="register" style={{ height: '100%' }}>
          <div className="card-body">
            <div style={{ fontSize: titleFontSize, fontWeight: 'bold', textAlign: 'center' }} className="option-name">Select Categories of Interest</div>
            <div className="categories-list" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
              {
                list.map(item => {
                  return (
                    <span
                      key={item}
                      onClick={() => choose(item)}
                      style={{ margin: "5px" }}
                      className={c("btn "+buttonClass, isChosen(item) ? { 'btn-dark': true } : { 'btn-outline-dark ': true } )}
                    >
                    { item }
                  </span>
                  )
                })
              }
            </div>
          </div>
          <button onClick={() => setStep(3)} disabled={categories.length === 0} className="btn btn-dark">Next</button>
        </div>
      </div>
    </div>
  )
}

export default CategoriesSelect
