import http from '../../utils/axios-interceptor'

import { START_FETCH_CATEGORIES, SUCCESS_FETCH_CATEGORIES, ERROR_FETCH_CATEGORIES } from "./actionTypes";

export const requestCategories = () => async dispatch => {
    dispatch({ type: START_FETCH_CATEGORIES })
    try {
        const res = await http.get(`api/categories/all`)
        dispatch({ type: SUCCESS_FETCH_CATEGORIES, payload: res.data.categories })
    } catch (err) {
        dispatch({ type: ERROR_FETCH_CATEGORIES }, err)
    }
}

