import React, {useContext, useEffect} from "react";
import c from 'classnames'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {ScrollContext} from "../../../context/scroll";
import {CloseOutlined} from '@ant-design/icons'
import {closeSavedPosts} from "../../../ducks/savedPosts/actions";
import { removeUserFromList } from "../../../ducks/visitedUsers/actions";
import MyLogo from "../../User/MyLogo";
import {SystemContext} from "../../../context/system";

const VisitedList = (
  {
    removeUserFromList,
    visitedUsers: {list},
    savedPosts: {isShownSavedPost},
    location: {pathname},
    history,
  }) => {
  const {setTranslate} = useContext(ScrollContext);
  const { toggleSettingsSider } = useContext(SystemContext);
  const showVisitedProfile = (id) => {
    history.push(`/users/${id}`);
    setTranslate(-1000);
  };

  return (
    <div className="visited-list">
        <MyLogo/>
      <div className="visited-list__items">
        {list.map((i, ind) => (
          <div
            key={ind}
            style={{position: 'relative', overflow: 'visible'}}
            onClick={() => showVisitedProfile(i._id)}
            className={c('visited-list__item', {'active-item': pathname.indexOf(i._id) > -1})}
          >
            <CloseOutlined
              onClick={(e) => {
                e.stopPropagation();
                if (pathname.indexOf(i._id) > -1) history.push('/home')
                removeUserFromList(i._id);
                toggleSettingsSider(false)
              }}
              className="close-icon"
            />
            <div style={{backgroundImage: `url(${i.img})`, borderRadius: '50%'}}/>
          </div>
        ))}
      </div>

      <style jsx="true">{`
        .close-icon {
          font-size: 20px;
          position: absolute;
          top: -4px;
          right: -9px;
          outline: none;
        }

        .close-icon svg {
          fill: #FFF;
        }
      `}</style>
    </div>
  );
};

export default withRouter(connect(state => ({visitedUsers: state.visitedUsers, savedPosts: state.savedPosts}), {
  closeSavedPosts,
  removeUserFromList
})(VisitedList));
