import React, {useContext} from 'react'
import c from 'classnames'
import {connect, useDispatch} from 'react-redux'
import Themes from "../SettingsPopup/Themes";
import Slider from "../SettingsPopup/Slider";
import {SystemContext} from "../../context/system";
import {ModalContext} from "../../context/modal";
import {requestLogout} from "../../ducks/auth/actions"

export default connect(state => ({user: state.user}))(({user}) => {

    const dispatch = useDispatch()

    const {
        settingsSider,
        toggleSettingsSider
    } = useContext(SystemContext);

    const { setModalType } = useContext(ModalContext);

    return (
        <div className={c('settings-sider-options', {opened: settingsSider})}>
            <div className="container">
                <div className="section">
                    {
                        localStorage.getItem('token') &&
                        <img
                            onClick={() => setModalType("load-image")}
                            className="avatar"
                            src={user.data.img}
                            alt="Profile"
                        />
                    }
                </div>
                <div className="section">
                    <h3 className="section-title">Themes</h3>
                    <Themes/>
                </div>
                <div className="section">
                    <h3 className="section-title">Speed</h3>
                    <Slider/>
                </div>
                {
                    localStorage.getItem('token') &&
                    <div className="section-buttons">
                        <button onClick={() => dispatch(requestLogout())}>Logout</button>
                    </div>
                }
            </div>
        </div>
    )
})