import React, {useCallback, useEffect, useState} from "react";
import c from "classnames";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Comment = ({text, direction, poster, user}) => {

    const [expandToggle, SetExpandToggle] = useState(false)
    const [commentText, SetCommentText] = useState("")

    const handleLink = useCallback(() => {
        if (user._id) {
            return user._id === poster._id
                ? "/me"
                : `/users/${poster._id}`;
        }
    }, [user])

    useEffect(() => {
        if (text.length > 200) {
            SetCommentText(text.substring(0, 200))
        } else SetCommentText(text)
    }, [])

    const expandComment = () => {
        SetExpandToggle(!expandToggle)
    }

    return (
        <div className={c("comment", {"expanded-comment": expandToggle})}>
            <Link to={handleLink}>
                {
                    poster._id !== user._id
                    ? <div className={"comments__" + direction + "__list__avatar"}
                         style={{backgroundImage: `url(${poster.img})`}}
                    />
                    : null
                }
            </Link>
            <p className={c("paragraph", {"expanded-paragraph": expandToggle})}>
                <span>{commentText}</span>
                {text.length > 200 ?
                    <i onClick={expandComment} className={"fas fa-expand-alt"}></i>
                    : null
                }
            </p>
        </div>
    );
};

export default connect(state => ({ user: state.user.data }))(Comment);