import ReactDOM from 'react-dom';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import c from "classnames";

const Control = ( {active, className, controlIcon, onClick } ) => {
    return (
        <div className={c(`${className} control inline`, {'active-item': active})} onClick={onClick}>
            <i className={"fa fa-".concat(controlIcon)}></i>
        </div>
    );
}

Control.propTypes = {
    controlIcon: PropTypes.string
}

export default Control;