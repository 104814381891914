import React, { useEffect, useContext } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BubblesList from "../../Components/Bubbles/BubblesList";
import { requestUserPosts } from "../../ducks/userPosts/actions";
import {SystemContext} from "../../context/system";
import c from "classnames";

const UserBubbleList = ({ match: { params: { id } }, posts: { list }, requestUserPosts }) => {
  useEffect(() => {
    requestUserPosts(id)
  }, [id])
    const {
        bounce
    } = useContext(SystemContext);

  return (
    <div className="bubbles-container" key={id}>
      <div className={c("bubbles-container__screen animated", bounce === "bounceInLeft"?{ "bounceInLeft": id }:{ "bounceInRight": id })}>
        <BubblesList posts={list} />
      </div>
    </div>
  );
};

export default withRouter(connect(state => ({ posts: state.userPosts }), { requestUserPosts })(UserBubbleList));
