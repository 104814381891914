import React, {useEffect, useContext} from "react";
import {connect} from "react-redux";
import BubblesList from "../../Components/Bubbles/BubblesList";
import {requestMyPosts} from "../../ducks/myPosts/actions";
import {SystemContext} from "../../context/system";
import c from "classnames";

const ProfileBubbleList = ({user: {data: {_id}}, posts: {list}}) => {
    const {
        bounce
    } = useContext(SystemContext);

    return (
        <div className="bubbles-container">
            <div key={_id}
                 className={c("bubbles-container__screen animated", bounce === "bounceInLeft" ? {"bounceInLeft": _id} : {"bounceInRight": _id})}>
                <BubblesList posts={list}/>
            </div>
        </div>
    );
};

export default connect(state => ({posts: state.myPosts, user: state.user}))(ProfileBubbleList);
