import React from "react";
import PropTypes from "prop-types";

const Count = ({ count, countName }) => {
    return (
        <div className="lt-count-container d-flex">
            <div className="lt-count-name ">
                {countName}:
            </div>
            <div className="lt-count ">
                {count || 0}
            </div>
        </div>
    );
};

Count.propTypes = {
    count: PropTypes.number,
    countName: PropTypes.string
};

export default Count;
