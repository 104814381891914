import http from '../../utils/axios-interceptor'
export const REQUEST_USER = 'REQUEST_USER'
export const SUCCESS_USER = 'SUCCESS_USER'
export const ERROR_USER = 'ERROR_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const LOGOUT_USER = 'LOGOUT_USER'
export const REQUEST_USER_FOLLOWING = 'REQUEST_USER_FOLLOWING'
export const SUCCESS_USER_FOLLOWING = 'SUCCESS_USER_FOLLOWING'
export const REQUEST_USER_ALL_PEOPLE = 'REQUEST_USER_ALL_PEOPLE'
export const SUCCESS_USER_ALL_PEOPLE = 'SUCCESS_USER_ALL_PEOPLE'

export const requestUser = () => async dispatch => {
  dispatch({type: REQUEST_USER})
  try {
    const res = await http.get('/api/user/me')
    dispatch({ type: SUCCESS_USER, payload: res.data.user })
  } catch (err) {
    dispatch({ type: ERROR_USER, payload: err })
  }
}

export const requestUserFollowing = () => async dispatch => {
  dispatch({type: REQUEST_USER_FOLLOWING})
  const res = await http.get('/api/user/following-people')
  dispatch({ type: SUCCESS_USER_FOLLOWING, payload: res.data.users })
}

export const requestUserAllPeople = () => async dispatch => {
  dispatch({type: REQUEST_USER_ALL_PEOPLE})
  const res = await http.get('/api/user/all-people')
  dispatch({ type: SUCCESS_USER_ALL_PEOPLE, payload: res.data.users })
}

export const setUser = (user) => dispatch => {
  dispatch({ type: SUCCESS_USER, payload: user })
}

export const errorUser = (error) => dispatch => {
  dispatch({ type: ERROR_USER, payload: error })
}

export const updateUser = (data, cb) => async dispatch => {
  dispatch({ type: REQUEST_USER })
  try {
    const res = await http.post('/api/user/profile-image', data)
    dispatch({ type: UPDATE_USER, payload: res.data.user })
    cb && cb()
  } catch (err) {
    dispatch({ type: ERROR_USER })
  }
}

export const updateUserData = (data, cb) => async dispatch => {
  dispatch({ type: REQUEST_USER })
  try {
    const res = await http.post('/api/user/update', data)
    dispatch({ type: UPDATE_USER, payload: res.data.user })
    cb && cb()
  } catch (err) {
    dispatch({ type: ERROR_USER })
  }
}

export const updateUserCategories = (categories, cb) => async dispatch => {
  dispatch({ type: REQUEST_USER })
  try {
    const res = await http.post('/api/user/categories', { categories })
    dispatch({ type: UPDATE_USER, payload: res.data.user })
    cb && cb()
  } catch (err) {
    dispatch({ type: ERROR_USER })
  }
}


