import React from 'react'

export default ({ setOption, setStep }) => {
  return (
    <div className="col-lg-10 m-t-sm-4" id="register-for">
      <div className="row d-flex align-content-center justify-content-center">
        <div className="signup-option" onClick={() => {
          setOption('personal')
          setStep(2)
        }} id="personal">
          <div className="option-name">Personal</div>
          <div className="option-icon">
            <i className="fa fa-user"> </i>
          </div>
          <div className="option-description" id="for-individual">All Humans</div>
        </div>
        <div className="signup-option disabled">
          <div className="option-name">Brand</div>
          <div className="option-icon">
            <i className="fa fa-home">
            </i>
          </div>
          <div className="option-description">Products, stores & organizations</div>
        </div>
      </div>
    </div>
  )
}
