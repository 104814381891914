import React, {useState} from "react";
import { useSelector } from 'react-redux'
import FileUpload from "../../shared/FileUpload";
import Select from "react-select"
import Switch from "../../shared/Switch";

const Image = ({submit, isUploading, setIsUploading}) => {
    const [file, setFile] = useState(null);
    const [category, setCategory] = useState("");
    const [postTitle, addPostTitle] = useState("");
    const [isPublic, setIsPublic] = useState(true);
    const categories = useSelector(state => state.categories.list.map(category => category.name));

    const fileChange = value => {
        setFile(value);
    };

    const changePublic = () => {
        setIsPublic(!isPublic);
    };

    const submitForm = () => {
        if (!file) return false;
        const data = new FormData();
        data.append("imageFile", file);
        data.append("type", "image");
        data.append("title", postTitle);
        data.append("category", category || "empty");
        setIsUploading(true);
        submit(data);
    };

    const changePostTitle = e => {
        addPostTitle(e.target.value);
    };

    return (
        <div className="add-image-post post-fields">
            <FileUpload fileChange={fileChange} />
            <div className="caption">
                <textarea
                    onChange={changePostTitle}
                    placeholder="Enter text here"
                />
                <span className="corner-field bottom-left"/>
                <span className="corner-field top-right"/>
            </div>
            <div className="fields-group">
                <div className="fields-group__bottom">
                    <div className="fields-group__bottom__item">
                        <div className="category-select">
                            <span className="category-select__title">CATEGORY</span>
                            <Select
                                onChange={e => setCategory(e.value)}
                                placeholder="Select Category"
                                className="category-select-component"
                                options={categories.map(c => ({value: c, label: c}))}
                            />
                        </div>
                    </div>
                    <div className="fields-group__bottom__item">
                        <Switch checked={!isPublic} handleChange={changePublic}/>
                    </div>
                </div>
            </div>
            <div className="button-group">
                <button onClick={submitForm}>
                    POST
                    <span className="corner bottom-left"><i className="fas fa-chevron-up"/></span>
                </button>
            </div>
        </div>
    );
};

export default Image;
