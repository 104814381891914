import {
    REQUEST_USER,
    SUCCESS_USER,
    ERROR_USER,
    UPDATE_USER,
    LOGOUT_USER,
    REQUEST_USER_FOLLOWING,
    SUCCESS_USER_FOLLOWING, REQUEST_USER_ALL_PEOPLE, SUCCESS_USER_ALL_PEOPLE
} from "./actions";
import {TOGGLE_FOLLOW_USER} from "../followingPosts/actions";
import {TOGGLE_SAVE_POST} from "../savedPosts/actions";

const initialState = {
    loading: true,
    loaded: false,
    error: null,
    success: false,
    data: {},
    followingUsers: [],
    allPeople: [],
    finishedBootRequest: false,
}

export default (state = initialState, action) => {
    const {type, payload} = action
    let user = {}
    switch (type) {
        case REQUEST_USER:
            return {...state, loading: true, loaded: false, error: null, success: false}
        case SUCCESS_USER:
        case UPDATE_USER:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null,
                success: true,
                data: payload,
                finishedBootRequest: true
            }
        case ERROR_USER:
            return {...state, loading: false, loaded: false, error: payload, success: false, finishedBootRequest: true}

        case REQUEST_USER_FOLLOWING:
            return {...state}

        case SUCCESS_USER_FOLLOWING:
            return {...state, followingUsers: payload}

        case REQUEST_USER_ALL_PEOPLE:
            return { ...state, allPeople: [] }

        case SUCCESS_USER_ALL_PEOPLE:
            return { ...state, allPeople: payload }

        case TOGGLE_FOLLOW_USER:
            user = {...state.data};
            if (user.following.includes(payload.id)) {
                user.following = user.following.filter(s => s !== payload.id)
            } else {
                user.following.push(payload.id)
            }
            return {...state, data: {...user}}
        case TOGGLE_SAVE_POST:
            user = {...state.data};
            if (user.saves.includes(payload.post._id)) {
                user.saves = user.saves.filter(s => s !== payload.post._id)
            } else {
                user.saves.push(payload.post._id)
            }
            return {...state, data: {...user}}
        case LOGOUT_USER:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: null,
                success: false,
                data: {},
                finishedBootRequest: false
            }
        default:
            return state
    }
}
