import React, { useState } from 'react'
import RegisterForm from '../../Components/Register/RegisterForm'
import SignupOption from '../../Components/Register/SignupOption'
import CategoriesSelect from '../../Components/Register/CategoriesSelect'

const RegisterPage = () => {
  const [option, setOption] = useState('')
  const [categories, toggle] = useState([])
  const [step, setStep] = useState(1)
  const isChosen = (value) => categories.some(c => c === value)

  const choose = (value) => {
    let copy = [...categories]
    if (!isChosen(value)) {
      copy.push(value)
    } else {
      copy = copy.filter(c => c !== value)
    }
    toggle(copy)
  }

  return (
    <React.Fragment>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-6 offset-3 col-sm-4 offset-sm-4 col-xs-4 offset-xs-4">
            <div id="logo" className="mb-3">
              <a href="/"></a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row justify-content-center">
          { step === 1 && <SignupOption setOption={setOption} setStep={setStep} /> }
          { step === 2 && <CategoriesSelect categories={categories} isChosen={isChosen} choose={choose} setStep={setStep} /> }
          { step === 3 && <RegisterForm categories={categories} /> }
        </div>
      </div>
    </React.Fragment>
  )
}

export default RegisterPage
