import http from "../../utils/axios-interceptor";

export const REQUEST_FOLLOWING_USERS = "REQUEST_FOLLOWING_USERS";
export const SUCCESS_FOLLOWING_USERS = "SUCCESS_FOLLOWING_USERS";
export const ERROR_FOLLOWING_USERS = "ERROR_FOLLOWING_USERS";
export const TOGGLE_FOLLOW_USER = "TOGGLE_FOLLOW_USER";

export const requestFollowingUsers = () => async dispatch => {
    dispatch({ type: REQUEST_FOLLOWING_USERS })
    try {
        const res = await http.get('/api/posts/saved')
        dispatch({ type: SUCCESS_FOLLOWING_USERS, payload: res.data.users })
    } catch (err) {
        dispatch({ type: ERROR_FOLLOWING_USERS }, err)
    }
}

export const toggleFollowUser = (choice, id, cb) => async dispatch => {
    await http.post(`/api/user/${choice}/${id}`)
    dispatch({ type: TOGGLE_FOLLOW_USER, payload: { id } })
    cb()
}
